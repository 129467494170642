import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SwipeComponent } from './components/swipe/swipe.component';
import { SwipeDetailComponent } from './components/swipe-detail/swipe-detail.component';

const routes: Routes = [
  { path: '', component: SwipeComponent },
  { path: 'swipe-detail/:id', component: SwipeDetailComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SwipeRoutingModule {}

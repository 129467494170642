import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hire',
  templateUrl: './hire.component.html',
  styleUrls: ['./hire.component.css'],
})
export class HireComponent implements OnInit {
  @ViewChild('closeModal') closeModal: ElementRef;

  maxDate: any;
  photoShoots: any;
  shootTypes: any;
  bookingForm: FormGroup;
  agentForm: FormGroup;
  description: string;
  pricing: any;
  formData: FormData = new FormData();
  minDate = new Date();
  professionalRating: any;
  agentRating: any;
  maxDate1:any;
  minDate1:any;
  startDate1:any;
  maxDate2:any;
  minDate2:any;
  startDate2:any;
  constructor(
    public httpService: HttpService,
    private router: Router,
    private fb: FormBuilder,
 ) {
      const currentDate = new Date();
      this.maxDate1 = {year: 2070, month: 1, day: 1};
      this.minDate1 =  {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};

      this.maxDate2 = {year: 2070, month: 1, day: 1};
      this.minDate2 =  {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};
      // this.startDate1 = { year: 1988, month: 1, day: 1 };
      // config.outsideDays = 'hidden';
    }

  ngOnInit(): void {
    this.getProfessionals();
    this.agentPricing();
    this.bookingForm = this.fb.group({
      date: ['', [Validators.required]],
      time: ['', [Validators.required]]
    })

    this.agentForm = this.fb.group({
      startDate : ['', [Validators.required]],
      endDate : ['', '']
    })
  }

  getProfessionals() {
    this.httpService.httpRequest(`professional/getProfessional`, HttpVerb.GET).
      subscribe((response: any) => {
        console.log(response);
        if (response.responseCode == 200) {
          this.photoShoots = response.responseData.pastShoots;
          this.shootTypes = response.responseData.types;
          this.professionalRating = response.responseData.rating;
          let temp = [];
          let rateTemp = []
          this.professionalRating.forEach(element => {
            for (let index = 0; index < element.rating; index++) {
              rateTemp.push(index);
          }
            element.rating = rateTemp;
            rateTemp = [];
            temp.push(element);
          });
          this.professionalRating = temp;
          // console.log(this.professionalRating);
        }
      })
  }

  Booking() {
    this.bookingForm.value.date = moment(this.bookingForm.value.date).format('YYYYMMDD');
    this.bookingForm.value.time = this.bookingForm.value.time.replace(":","");
    // console.log(this.bookingForm.value);
    localStorage.setItem("bookingData", JSON.stringify(this.bookingForm.value));
    let amount = 100; 
    let type = "professional";
    this.httpService.httpRequest(`user/payForBooking?amount=${amount}&type=${type}`, HttpVerb.GET).
      subscribe((response: any) => {
        window.open(response.responseData.paymentUrl,"_self");
      })
  }

  getAgent(){
    this.agentForm.value.endDate = moment(this.agentForm.value.startDate).add(7, 'days').subtract(1, 'months').format('YYYYMMDD');
    this.agentForm.value.startDate = moment(this.agentForm.value.startDate).subtract(1, 'months').format('YYYYMMDD');
    
    console.log(this.agentForm.value);
    localStorage.setItem("agentData", JSON.stringify(this.agentForm.value));
    let amount = 20; 
    let type = "agent";
    // this.router.navigate(['/success']);
    this.httpService.httpRequest(`user/payForBooking?amount=${amount}&type=${type}`, HttpVerb.GET).
      subscribe((response: any) => {
        window.open(response.responseData.paymentUrl,"_self");
      })
  }

  agentPricing(){
    this.httpService.httpRequest(`agent/getAgent`, HttpVerb.GET).
      subscribe((response: any) => {
        // console.log(response);
        this.description = response.responseData.description;
        this.pricing = response.responseData.pricing
        this.agentRating = response.responseData.rating;
        let temp = [];
        let rateTemp = []
        this.agentRating.forEach(element => {
          for (let index = 0; index < element.rating; index++) {
            rateTemp.push(index);
        }
          element.rating = rateTemp;
          rateTemp = [];
          temp.push(element);
        });
        this.agentRating = temp;
        // console.log(this.agentRating);
      })
  }

  changeStratDate(sd){
    let newDate= new Date(sd.year, sd.month-1, sd.day,0,0,0,0);
    let endDate;
    console.log(this.agentForm.value);
    endDate = moment(newDate, "DD-MM-YYYY").add(7, 'days').format('YYYY-MM-DD');
    // endDate = endDate.add(7, 'days');
    // console.log(endDate);
    this.agentForm.patchValue({
      endDate: endDate
    })
    console.log("date change again");
  }
}

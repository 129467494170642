import { Component, OnInit } from '@angular/core';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
  }

  sentOtp(){
    this.httpService.httpRequest(`user/forgotPassword`, HttpVerb.POST, '').
      subscribe((response: any) => {
        console.log(response.responseData);
      })
  }

}

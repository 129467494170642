
<div class="common-wrapper">
  <div class="container">
    <div class="agent-detail-wrapper flex-mkt">
      <div class="col-sm-4">
        <figure>
          <img src="assets/images/mask-group.jpg" alt="">
        </figure>
      </div>
      <div class="col-sm-8">
        <span class="suggestion-block">Profile  Suggestions By Agent</span>
        <h1>Dollie Lynch</h1>
        <div class="agent-details flex-mkt">
          <div class="agent-match-block">
            <span>New Match</span>
            <span class="point-block">1 Out Of 5</span>
          </div>
          <div class="agent-status-block">
            <span>Package Status : </span>
            <span class="days-block">Day 2 Out Of 6</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

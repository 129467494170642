<div class="register-block flex-mkt">
  <span class="register-close" #closeModal data-dismiss="modal" (click)="resetForm()"></span>
  <div class="left-register-block">
<!--    <figure>-->
      <img src="assets/images/register-image.png" alt="">
<!--    </figure>-->
    <div class="welcome-block">
      <h1>Hi, Welcome</h1>
      <span>create account</span>
    </div>
  </div>
<!--  {{registerForm | json}}-->
  <div class="right-register-block">
    <form [formGroup]="registerForm" novalidate>
      <span>Fill the form below:</span>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Full Name" formControlName="name" >
      </div>
      <div *ngIf="registerForm.controls['name'].invalid && (registerForm.controls['name'].dirty || registerForm.controls['name'].touched)" class="alert alert-danger">
        <div *ngIf="registerForm.controls['name'].errors.required">
           Name is required.
        </div>
      </div>
      <div class="form-group">
        <input type="email" class="form-control" placeholder="Email Address"  formControlName="email">
      </div>
      <div *ngIf="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)" class="alert alert-danger">
        <div *ngIf="registerForm.controls['email'].errors.required">
          Email is required.
        </div>
      </div>
      <div class="form-group">
        <select name="countryCode" id="countryCode" formControlName="countryCode" class="dropDown">
          <option value="" disabled selected>Select Country Code</option>
          <option value="+91">+91 (India)</option>
          <option value="+81">+81</option>
          <option value="+85">+85</option>
          <option value="+92">+92</option>
      </select>
      </div>
      <div *ngIf="registerForm.controls['countryCode'].invalid && (registerForm.controls['countryCode'].dirty || registerForm.controls['countryCode'].touched)" class="alert alert-danger">
        <div *ngIf="registerForm.controls['countryCode'].errors.required">
          Country Code is required.
        </div>
      </div>
      <div class="form-group">
        <input type="tel" class="form-control" placeholder="Mobile Number"  formControlName="phone">
      </div>
        <div *ngIf="registerForm.controls['phone'].invalid && (registerForm.controls['phone'].dirty || registerForm.controls['phone'].touched)" class="alert alert-danger">
          <div *ngIf="registerForm.controls['phone'].errors.required">
            Mobile Number is required.
          </div>
        </div>
      <div class="form-group">
        <input type="password" class="form-control" placeholder="Create Password" formControlName="password" >
      </div>
          <div *ngIf="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)" class="alert alert-danger">
            <div *ngIf="registerForm.controls['password'].errors.required">
              Password is required.
            </div>
          </div>
          <div *ngIf="showOTP" class="form-group">
            <input type="text" class="form-control" placeholder="Enter OTP" formControlName="otp" >
          </div>
              <div *ngIf="registerForm.controls['otp'].invalid && (registerForm.controls['otp'].dirty || registerForm.controls['otp'].touched)" class="alert alert-danger">
                <div *ngIf="registerForm.controls['otp'].errors.required">
                  OTP is required.
                </div>
              </div>

              <div *ngIf="showOTP" class="submit-block">
                <button type="submit" (click)="onVerify()" >Verify</button>
                <a ><img src="assets/images/facebook.png"></a>
                <a><img src="assets/images/Group.png"></a>
              </div>

      <div *ngIf="!showOTP" class="submit-block">
        <button type="submit" (click)="onSubmit()" >Sent OTP</button>
        <a ><img src="assets/images/facebook.png"></a>
        <a (click)="onGoogleSignIn()"><img src="assets/images/Group.png"></a>
      </div>
      <!-- <p>Don't have an account? <a  class="login-anchr login-flip">login</a></p> -->
    </form>
  </div>
</div>


<div id="wrapper">

  <div class="common-wrapper">
    <div class="container">
      <div class="subscription-section chnge-head" *ngFor="let product of productData">
        <h1>{{product.subName}}</h1>
        <!-- <p>Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck
          sriracha cornhole single-origin coffee church-key roof party.Synth polaroid bitters chillwave pickled. Vegan
          disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole single-origin coffee church-key roof
          party.Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck
          sriracha cornhole single-origin coffee church-key roof party.</p> -->
        <div class="feature-block">
          <h4>Features</h4>
          <p>{{product.subDescription}}</p>
        </div>
        <div class="pricing-block">
          <h1>Pricing</h1>
          <div class="price"><span>$</span>{{product.amount}}</div>
        </div>
        <button (click)="buySubscription(product.priceId)">Buy Now</button>
        <br> <br> <br>
      </div>
    </div>
  </div>

</div>


import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import Swiper from 'swiper';
declare var $: any;
@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css'],
})
export class ExploreComponent implements OnInit {
  title = 'firstApp';
  
  lat: any;
  long: any;
  constructor(public httpService: HttpService) {}
  profiles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; // change elements with real data

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    console.log('slide change');
  }
  data: any;
  ngOnInit(): void {
    // $(document).ready(function () {
    //   $('.menu-icon').on('click', function () {
    //     $('nav ul').toggleClass('showing');
    //   });
    // });

    // // Scrolling Effect

    // $(window).on('scroll', function () {
    //   if ($(window).scrollTop()) {
    //     $('nav').addClass('black');
    //   } else {
    //     $('nav').removeClass('black');
    //   }
    // });
    this.httpService.getPosition().then(pos => {
      // let obj = {
      //   lat: pos.lat.toString(),
      //   long: pos.lng.toString()
      // }
      this.lat = pos.lat.toString(),
      this.long = pos.lng.toString()
    })
  }
}

import { Component, OnInit } from '@angular/core';
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal',
  template: `
    <ng-template #content let-c="close" let-d="dismiss">
      <div class="register-block flex-mkt">
        <span class="register-close" data-dismiss="modal"></span>
      </div>

    </ng-template>

    <button class="btn btn-lg btn-outline-primary" (click)="open(content)">Launch demo modal</button>
  `,
  styles: [
  ],
  providers:[NgbModalConfig,NgbModal]
})
export class ModalComponent {

  constructor(config:NgbModalConfig, private  modalService:NgbModal) {
    config.backdrop = 'static';
    config.keyboard =false;
  }
open = content => this.modalService.open(content)

}

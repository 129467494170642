import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-razorpay',
  templateUrl: './razorpay.component.html',
  styleUrls: ['./razorpay.component.css']
})
export class RazorpayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
   loadScript = (src) =>{
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
   handleSubmitRazorPay = async(event) =>{
    event.preventDefault();
    const res = await this.loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    // const data = await fetch('http://localhost:5001/tinder-clone-ae16e/us-central1/api/razorpay', { method: 'POST' }).then((t) =>
    //   t.json()
    // )

    // console.log(data)

    const options = {
      key:'rzp_test_ucExyTywTG0t4T',
      currency: 'INR',
      amount: 535,
      order_id: 'g',
      name: 'Orders',
      description: 'bsdk',
      image: 'http://localhost:5001/tinder-clone-ae16e/us-central1/api/logo.svg',
      prefill: {
        name,
        email: 'sdfdsjfh2@ndsfdf.com',
        phone_number: '9899999999'
      },
      external: {
        wallets: ['phonepe']

      }
    }
    // const paymentObject = new window.Razorpay(options)
    // paymentObject.open()
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  blog_id: string;
  blogData: any;
  startTime: any;
  endTime: any;
  constructor(
    private route: ActivatedRoute,
    public httpService: HttpService) { }

  ngOnInit(): void {
    this.startTime = Date.now();
    this.route.params.subscribe(params => {
      this.blog_id = params.blog_id;
    });
    console.log(this.blog_id);
    this.getBlog();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
});
  }

  getBlog() {
    this.httpService.httpRequest(`blog/getBlogById?blogId=${this.blog_id}`, HttpVerb.GET).
      subscribe((response: any) => {
        if (response.responseCode == 200) {
          this.blogData = response.responseData;
        }
        console.log(response);
      })
  }

  ngOnDestroy(): void {
    this.endTime = Date.now();
    let timeDiff = this.endTime - this.startTime;
    if (timeDiff >= 30000) {
      let obj = { cout: 1 };
      this.httpService.httpRequest(`blog/updateBlogReadCount`, HttpVerb.POST, obj).
        subscribe((response: any) => {
          console.log("API Hit", response);
        })
    }
  }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {catchError, take} from "rxjs/operators";
import { BehaviorSubject, Observable, throwError} from "rxjs";
import { HttpVerb} from "../enum/const.enum";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  currentMessage = new BehaviorSubject(null);

  apiKey = environment.API_KEY;

  constructor(private http:HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging) { 
      this.angularFireMessaging.messages.subscribe(
        (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      })
     }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  httpRequest(endPoint:string, method:HttpVerb, payload?: any):Observable<any> {
    let response;
    switch (method) {
      case HttpVerb.GET:
      return   response = this.http.get(`${environment.baseUrl}${endPoint}`)
        .pipe(catchError((err) =>this.handleError(err,this)));
      case HttpVerb.POST:
      return   response = this.http.post(`${environment.baseUrl}${endPoint}`, payload)
        .pipe(catchError((err) =>this.handleError(err,this)))
      case HttpVerb.PATCH:
       return  response = this.http.patch(`${environment.baseUrl}${endPoint}`,payload, this.httpOptions)
         .pipe(catchError((err) =>this.handleError(err,this)))

      case HttpVerb.DELETE:
      return   response = this.http.delete(`${environment.baseUrl}${endPoint}`)
        .pipe(catchError((err) =>this.handleError(err,this)))

      case HttpVerb.UPDATE:
       return  response = this.http.put(`${environment.baseUrl}${endPoint}`,payload, this.httpOptions)
         .pipe(catchError((err) =>this.handleError(err,this)));
      default:
        break;
    }
  }

  /**
   *
   * @param error
   * @param self
   */

  handleError(error:HttpErrorResponse, self) {
    // console.log("qwertyuiop>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    if (error.error instanceof ErrorEvent) {
      console.error('A error occurred:', error.error.message);
    } else {
      // this._error.whichError()
      // console.log(error);
      if(error.status == 401){
        this.removeToken();
        this.toastr.error("Unauthorized Access")
        this.router.navigate(['/home']);
        return throwError({error:error.message, status:error.status})  
      }
    }
  }

  /**
   *
   *
   * token manage
   */

  TOKEN_KEY='auth_token';
  saveToken=(userData: any)=> {
    sessionStorage.clear();
    // sessionStorage.setItem(this.TOKEN_KEY, userData.accessToken)
    sessionStorage.setItem('currentUser', JSON.stringify(userData));
  }

  currentUser: any = {};
  getToken=() => {
    this.currentUser = sessionStorage.getItem('currentUser');
    this.currentUser = JSON.parse(this.currentUser);
    if(this.currentUser){
      return this.currentUser.accessToken;
    }else{sessionStorage.getItem('currentUser')}
}

removeToken=() =>{
    return window.sessionStorage.clear();
}

getPosition(): Promise<any>
{
  return new Promise((resolve, reject) => {

    navigator.geolocation.getCurrentPosition(resp => {

        resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
      },
      err => {
        reject(err);
      });
  });

}


 /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokens/').update(data)
        let obj = {deviceToken: token}
        this.httpRequest(`user/updateToken`, HttpVerb.UPDATE, obj).subscribe(data => {
          console.log(data);
        })
      })
  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }


  displayLocation(latitude,longitude){
    var geocoder;
    geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(latitude, longitude);
    console.log(latlng);
  
    geocoder.geocode(
        {'latLng': latlng}, 
        function(results, status) {
          console.log(results);
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    var add= results[0].formatted_address ;
                    var  value=add.split(",");
  
                    return value;
                    // count=value.length;
                    // country=value[count-1];
                    // state=value[count-2];
                    // city=value[count-3];
                    // x.innerHTML = "city name is: " + city;
                }
                else  {
                  return "address not found";
                    // x.innerHTML = "address not found";
                }
            }
            else {
              return status;
                // x.innerHTML = "Geocoder failed due to: " + status;
            }
        }
    );
  }

  googleSignIn(idToken){
    console.log(idToken);
    return this.http.post(`https://identitytoolkit.googleapis.com/v1/accounts:signInWithIdp?key=${this.apiKey}`, {
      postBody:`id_token=${idToken}&providerId=google.com`,
      requestUri:'https://royal-flings.ch',
      returnIdpCredential:true,
      returnSecureToken:true
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';
// import Swal from 'sweetalert2';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  optField: boolean = false;
  reset: boolean = false;
  forgotForm: FormGroup;
  resetForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private router: Router,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.forgotForm = this.fb.group({
      countryCode: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      otp: ['','']
    });

    this.resetForm = this.fb.group({
      newPassword: ['',[Validators.required]],
      confPassword: ['', [Validators.required]]
    });
  }

  sentOtp(){
    this.httpService.httpRequest(`user/forgotPassword`, HttpVerb.POST, this.forgotForm.value).subscribe((data: any)=> {
      // alert(data.responseMessage);
      if(data.responseCode==200){
        // Swal.fire("OTP Sent",data.responseMessage, "success")
        this.toastr.success(data.responseMessage, 'OTP Sent!');
        this.optField = true;
      }else{
        this.toastr.error(data.responseMessage);
      }
    })
  }

  onSubmit(){
    this.httpService.httpRequest(`user/verifyotpForSignUp`, HttpVerb.POST, this.forgotForm.value).subscribe((data: any)=> {
      // alert(data.responseMessage);
      if(data.responseCode==200){
        this.toastr.success(data.responseMessage, 'Verified');
        // Swal.fire("Verified",data.responseMessage, "success")
        this.reset = true;
      }else{
        this.toastr.error(data.responseMessage);
      }
    })
  }

  resetPassword(){
    if(this.resetForm.value.newPassword == this.resetForm.value.confPassword){
      let obj = {
        phone: this.forgotForm.value.phone,
        newPassword: this.resetForm.value.newPassword
      }
      this.httpService.httpRequest(`user/resetPassword`, HttpVerb.POST, obj).subscribe((data: any)=> {
        // Swal.fire("Password Change",data.responseMessage, "success")
        // this.toastr.success(data.responseMessage, 'Password Change');
        // alert(data.responseMessage);
        if(data.responseCode==200){
          this.toastr.success(data.responseMessage, 'Password Change');
          this.reset = true;
          this.router.navigate(['/home']);
        }else{
          this.toastr.error(data.responseMessage);
        }
      })
    }else{
      this.toastr.error('New and Confirm Password does not match');
      // alert("New and Confirm Password does not match");
    }
    
  }

  backtoHome(){
    this.router.navigate(['/home']);
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './modules/auth/auth.module';
import { HomeModule } from './modules/home/home.module';
import { LayoutRoutingModule } from './shared/layout/layout-routing.module';
import { BlogRoutingModule } from './modules/blog/blog-routing.module';
import { SwipeRoutingModule } from './modules/swipe/swipe-routing.module';
import { FlingsRoutingModule } from './modules/flings/flings-routing.module';
import { SwiperModule } from 'swiper/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentRoutingModule } from './modules/payment/payment-routing.module';
import {NgxsModule} from "@ngxs/store";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {NgxsLoggerPluginModule} from "@ngxs/logger-plugin";
import {HttpService} from "./core/services/http/http.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LayoutModule} from "./shared/layout/layout.module";
import {TokenInterceptor} from "./core/services/interceptors/token.interceptor";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DatepickerModule } from 'ng2-datepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
// import {RouterState} from './shared/ngxs/router.state';
// import {AppState} from './shared/ngxs/app.state';



@NgModule({
  declarations: [AppComponent, PaymentSuccessComponent, PaymentFailComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxsModule.forRoot([]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    AuthModule,
    HomeModule,
    LayoutRoutingModule,
    BlogRoutingModule,
    SwipeRoutingModule,
    FlingsRoutingModule,
    SwiperModule,
    NgbModule,
    PaymentRoutingModule,
    LayoutModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({maxOpened: 1, autoDismiss: true,}), // ToastrModule added
    DatepickerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxStarRatingModule,
    NgxIntlTelInputModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    SocialLoginModule
  ],
  providers: [
    HttpService, 
    AsyncPipe,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptor, 
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              // '712961651216-of340sgoql9e464rsinsdni4h1ba7dp8.apps.googleusercontent.com'
              // '429678272216-au0d5ss7rat9n6i1loh9qsn3fb4rmik5.apps.googleusercontent.com'
              '712961651216-mk1ranelevke0hbtm9sumd08igcnpv8p.apps.googleusercontent.com'
           ,{ scope: 'profile email',
           plugin_name:'sample_login' 
          } ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '988507309239305'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

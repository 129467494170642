<!-- <div class="wrapper">
  <div class="common-wrapper">
    <div class="container">
      <div class="explore-section chnge-head">
        <h1>Explore</h1>
        <div class="map-section">
          <img src="assets/images/map-image.png" alt="">
        </div>
      </div>
    </div>
  </div>

</div> -->

<!-- <agm-map [zoom]="18">
  <agm-marker></agm-marker>
</agm-map> -->
<div style="padding-inline-start: 250px;">
  <agm-map [latitude]="lat" [longitude]="long" [zoom]="15">
    <agm-marker [latitude]="lat" [longitude]="long" [label]="'D'"></agm-marker>
  </agm-map>
</div>

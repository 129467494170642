	<!--Page Wrapper Start-->		
	<div id="wrapper">

        <div class="common-wrapper">
            <div class="contianer">
                <div class="thank-block">
                    <figure>
                        <img src="assets/images/thank-image.png" alt="">
                    </figure>
                    <h1>Your payment is Successfull</h1>
                    <span></span>
                </div>
            </div>
        </div>

	</div>
	<!--Page Wrapper End-->
<!-- <div class="login-wrapper"> -->
<div class="register-block flex-mkt">
  <span class="login-close" #closeModal data-dismiss="modal" (click)="resetForm()"></span>
  <div class="left-register-block">
    <figure>
      <img src="assets/images/register-image.png" alt="">
    </figure>
    <div class="welcome-block">
      <h1>Hi, Welcome</h1>
      <span>create account</span>
    </div>
  </div>
  <div class="right-register-block">
    <form [formGroup]="loginForm">
      <span>Fill the form below:</span>
      <!-- <div class="form-group">
        <select name="countryCode" id="countryCode" formControlName="countryCode" class="dropDown">
          <option value="" disabled selected>Select Country Code</option>
          <option value="+91">+91 (India)</option>
          <option value="+81">+81</option>
          <option value="+85">+85</option>
          <option value="+92">+92</option>
        </select>
      </div> -->

      <div class="form-group">
        <ngx-intl-tel-input
        style="width: 300px;"
    [preferredCountries]="['in']"
    [enablePlaceholder]="true"
    [enableAutoCountrySelect]="true"
    [separateDialCode]="true"
    name="phone"
        formControlName="phone"></ngx-intl-tel-input>
      </div>

      <!-- <div class="form-group">
        <input type="tel" name="lmob" class="form-control" formControlName="phone" placeholder="Mobile" required>
      </div> -->
      <div class="form-group">
        <input type="password" name="lpasssword" class="form-control" formControlName="password" 
        placeholder="Password" style="width: 250px;" required>
      </div>
      <div class="submit-block">
        <button type="submit" (click)="login()" [disabled]="loggedinClicked">Login</button>
        <a (click)="loginWithFacebook()"><img src="assets/images/facebook.png"></a>
                 <a (click)="loginWithGoogle()"><img src="assets/images/Group.png" ></a>
      </div>
      <p>Don't have an account? <a type="button" (click)="openReg()" class="register-anchr" data-toggle="modal" data-target="#exampleModal">Register</a></p>
      <span>
        <a class="dating__forgot-pwd" style="margin-inline-start: 67px;" (click)="forgotPass()">Forgot password?</a>
      </span>

    </form>

    <!-- <button id="find-me">Show my location</button><br />
    <p id="status"></p>
    <a id="map-link" target="_blank"></a> -->

  </div>
</div>
<!-- </div> -->



<!-- *new implimentation -->

<!--<div class="dating-app">-->
<!--  <div class="dating__logo"><img src="assets/images/heart.png" alt="dating app logo" /><span-->
<!--      class="dating__slogan">Flirt and meet new people</span></div>-->
<!--  <form class="dating__form">-->
<!--    <input class="dating__inp" name="mobile" formcontrol="mobile" type="number" name="mobile"-->
<!--      placeholder="Mobile Number" />-->
<!--    <input class="dating__inp" type="password" name="password" placeholder="Password" />-->
<!--    <button class="btn btn&#45;&#45;primary mt-15">Login</button>-->
<!--    <div class="text&#45;&#45;center">-->
<!--      <a class="dating__forgot-pwd" [routerLink]="['/auth/forget']">Forgot password?</a></div>-->
<!--  </form>-->
<!--  <div class="dating__footer"><span class="dating__account">Don't have an account?</span><a class="btn btn&#45;&#45;secondary"-->
<!--      [routerLink]="['/auth/register']">Create</a></div>-->
<!--  <div class="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="default"-->
<!--    data-auto-logout-link="false" data-use-continue-as="true" onlogin="checkLoginState()"></div>-->


<!--</div>-->
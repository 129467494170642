import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ModalComponent } from '../modal/modal.component';
import {LayoutRoutingModule} from "./layout-routing.module";
import {AuthModule} from "../../modules/auth/auth.module";



@NgModule({
  declarations: [FooterComponent, HeaderComponent, ModalComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    AuthModule,

  ],
  exports:[
    FooterComponent,
    HeaderComponent
  ]
})
export class LayoutModule { }

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpService } from "../../../../core/services/http/http.service";
import { HttpVerb } from "../../../../core/services/enum/const.enum";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { SocialAuthService, GoogleLoginProvider, SocialUser ,FacebookLoginProvider} from 'angularx-social-login';
import { DataService } from 'src/app/core/services/http/data.service';

declare var FB: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  @ViewChild('closeModal') closeModal: ElementRef;

  loginForm: FormGroup;
  latitude: any;
  longitude: any;
  message: any;
  firebaseToken: any;
  loggedinClicked:boolean=false;

  constructor(private fb: FormBuilder, private httpService: HttpService,
    private route: Router,
    private http: HttpClient,
    private toastr: ToastrService, private socialAuthService: SocialAuthService,
    public dataService:DataService) { }

  loginUser = () => {
    this.loginForm = this.fb.group({
      deviceToken: ["qwerty", Validators.required],
      deviceTypeID: ["3", Validators.required],
      countryCode: ['', ''],
      phone: ['', Validators.required],
      password: ['', Validators.required],
    })
  }
  login1() {
    FB.login((response) => {
      console.log(response);
      if (response.authRespose) {
        console.log('success' + response.authRespose);
      } else {
        console.log('failure');
      }
    });
  }
  login = () => {
    this.loggedinClicked=true;
    console.log(this.loginForm.value);
    let loginObj = {
      deviceToken: this.loginForm.value.deviceToken,
      deviceTypeID: this.loginForm.value.deviceTypeID,
      countryCode: this.loginForm.value.phone.dialCode,
      phone: this.loginForm.value.phone.number?this.loginForm.value.phone.number.replaceAll(' ', ''):this.loginForm.value.phone.number,
      password: this.loginForm.value.password
    }
    console.log(loginObj);

    this.httpService.getPosition().then(pos => {
      let obj = {
        lat: pos.lat.toString(),
        long: pos.lng.toString()
      }
      console.log(loginObj);
      this.httpService.httpRequest(`user/login`, HttpVerb.POST, loginObj).
        subscribe((response: any) => {
          this.loggedinClicked=false;
          console.log(response);
          if (response.responseCode == 200) {
            console.log(response);
            this.httpService.saveToken(response.responseData);
            this.httpService.httpRequest(`user/updateLocation`, HttpVerb.POST, obj).
              subscribe((result: any) => {
                this.route.navigate(['/home']);
                this.toastr.success("Login Successful","",{
                  timeOut: 3000,
                });
                this.closeModal.nativeElement.click();
                this.httpService.requestPermission(response._id);
               
                  this.dataService.emitdata("refresh");
                  
              })
          }else{this.toastr.error(response.responseMessage,"",);}

        });
    }, error => {
      this.loggedinClicked=false;
      this.toastr.error("Please Allow to Access Location");
    })
  }

  checkLoginState() {
  }


  ngOnInit(): void {
    this.loginUser();
    // const userId = 'user001';
    // this.httpService.requestPermission(userId)
    // console.log(this.firebaseToken);
    this.httpService.receiveMessage()
    this.message = this.httpService.currentMessage;
    // console.log(this.message);
    
  }


  forgotPass() {
    this.closeModal.nativeElement.click();
    this.route.navigate(['/auth/forget']);
  }

  openReg(){
    this.closeModal.nativeElement.click();
  }
  resetForm(){
    this.loginForm.reset();
  }

   // Initial implicite flow using OAuth2 protocol
   loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.socialAuthService.authState.subscribe((user)=>{
      console.log("=========>Social Login User details",user);
     
      this.httpService.httpRequest('user/websocial-login', HttpVerb.POST, user).subscribe((response)=>{
        if(response.responseCode == 200){
          this.route.navigate(['/home']);
          this.closeModal.nativeElement.click();
          this.httpService.requestPermission(response._id);
          this.httpService.saveToken(response.responseData);
          this.toastr.success(response.responseMessage,"Login Sucessfully");
        }else{
          this.toastr.error(response.responseMessage);
        }
      })
     
    })
  }

  loginWithFacebook(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.socialAuthService.authState.subscribe((user)=>{
      console.log("=========>FacebookLoggedin details fetch of user details",user);
      this.httpService.httpRequest('user/websocial-login', HttpVerb.POST, user).subscribe((response)=>{
        if(response.responseCode == 200){
          this.route.navigate(['/home']);
          this.closeModal.nativeElement.click();
          this.httpService.requestPermission(response._id);
          this.httpService.saveToken(response.responseData);
          this.toastr.success(response.responseMessage,"Login Sucessfully");
        }else{
          this.toastr.error(response.responseMessage);
        }
      })
     
    })
  
  } 

  // Logout the current session
  logOut(): void {
    this.socialAuthService.signOut();
  }

}

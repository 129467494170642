import {Component, OnInit,DoCheck,AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import Swiper from 'swiper';
import {HttpService} from "../../../../core/services/http/http.service";
import {HttpVerb} from "../../../../core/services/enum/const.enum";
import { DataService } from 'src/app/core/services/http/data.service';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
  EffectCoverflow,
} from 'swiper/core';
declare var $: any;
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
  EffectCoverflow,
]);


export interface SwipeCard {
  image:string;
  status:string;
  name:string;
  location:string;
  age:string;
  details:string

}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit,DoCheck,AfterViewInit{
  dummy = '{dummy}';
  swiper: any;
  
  trendings = [1, 2, 3, 4, 5, 6, 7]; // change elements with real data
  profiles:SwipeCard[] =
    [{image:'assets/images/Mask Group_3.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},
    {image:'assets/images/Mask Group_4.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},
    {image:'assets/images/Mask Group_5.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},
    {image:'assets/images/Mask Group_6.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},
    {image:'assets/images/Mask Group_7.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},
    {image:'assets/images/Mask Group_8.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},
    {image:'assets/images/Mask Group_3.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},
    {image:'assets/images/Mask Group_3.png',status:'21y', name:'Garima',location:'Noida, India' ,age:'21y', details:'/blog-detail'},]; // change elements with real data

    topLike: any=[];
    topSuperLike: any=[];
    trending: any=[];
    videoUrl: any;
    currentUserAccess:any=false;
    trendingProfilesWeek=[];
    trendingProfilesDay:any=[];
  constructor(private route: Router, public httpService:HttpService,public dataService:DataService) {
   
  }

  swiperObject = () => {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 5,
      spaceBetween: 5,
      grabCursor: true,
      allowSlidePrev: false,
    });
  };

  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    // this.getUserList();
    console.log('slide change');
  }

  // scrollingEffect = () => {
  //   $(document).ready(function () {
  //     $('.menu-icon').on('click', function () {
  //       $('nav ul').toggleClass('showing');
  //     });
  //   });
  //
  //   // Scrolling Effect
  //
  //   $(window).on('scroll', function () {
  //     if ($(window).scrollTop()) {
  //       $('nav').addClass('black');
  //     } else {
  //       $('nav').removeClass('black');
  //     }
  //   });
  // };



  ngOnInit(): void {
    this.getDashboardData();
    this.swiperObject();
   let access=JSON.parse(sessionStorage.getItem('currentUser'))
  this.currentUserAccess=access?true:false;
    console.log("=====>current user access",)
    // this.formLogin();
    // this.scrollingEffect();

  }
  ngDoCheck(){
    if(!this.httpService.getToken()){
      this.topLike=[];
      this.topSuperLike=[];
      this.trending=[];
      this.trendingProfilesDay=[];
    }else{

    }
  }
  ngAfterViewInit(): void {
    this.getDashboardData();
    this.dataService.data$.subscribe(
      (data) => {
       if(data=='refresh'){
        this.getDashboardData();
       }
      }
    );
  }
 
  getDashboardData(){
    let newarr:any=[];

    let obj = {}
    this.httpService.httpRequest(`user/homePage`, HttpVerb.POST, obj).
      subscribe((response: any) => {
        this.trendingProfilesDay=[]
        // console.log(response);
        this.topLike = response.responseData.topLikedProfile;
        this.topSuperLike = response.responseData.topSuperLikedProfiles;
        this.trending = response.responseData.trendingProfiles;
        this.videoUrl = response.responseData.videoUrl?response.responseData.videoUrl:'https://s3.us-west-2.amazonaws.com/royal-flings.ch/productVideo/uploadMedia_1620754971667_SampleVideo_1280x720_1mb.mp4'
        this.trendingProfilesWeek=response.responseData.newSubscriptionsThisweek;
         newarr=response.responseData.newSubscribersToday;
     
          // console.log("=======>",this.trendingProfilesDay.length)
        for(let i=0;i<newarr.length;i++){
          // console.log("=======>",this.trendingProfilesDay[i].userDetails)
           let newObj={...newarr[i]?.userDetails[i]};
           this.trendingProfilesDay.push(newObj)
        }
        console.log("=======>",this.trendingProfilesDay)
        this.callOnceAgain();
      }, error => {
        console.log(error.err.message)
      })
  }

  callOnceAgain(){
    this.topLike= this.topLike;
    this.topSuperLike=this.topSuperLike;
    this.trending= this.trending;
    this.trendingProfilesDay= this.trendingProfilesDay;
  }
}

<div class="common-wrapper">
  <div class="container">
    <div class="common-head">
      <h1>Video</h1>
      <div class="form-group">
        <img src="assets/images/search-image.png" alt="">
        <input type="text" placeholder="Search">
        <button type="submit">Go</button>
      </div>
    </div>
    <div class="main-blog">
      <figure>
        <img src="assets/images/main-blog-image.png" alt="">
      </figure>
      <span class="category-block">Sports</span>
      <h3>How to stay fit</h3>
      <div class="blog-info-block">
        <span>By <a href="#">Jenelia Desoza</a></span>
        <span>June 3, 2018</span>
      </div>
    </div>
    <div class="blog-listing-section">
      <h1>Trending This Week</h1>
      <div class="blog-listing-wrapper flex-mkt">
        <div class="blog-listing-block col-sm-3" *ngFor="let block of blocks; index as i">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="{{block.src}}" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <ul class="category-listing">
      <li class="active"><a href="#sports_id">Sports</a></li>
      <li><a href="#lifestyle_id">Lifestyle</a></li>
      <li><a href="#">Dating</a></li>
      <li><a href="#">Friendship</a></li>
      <li><a href="#">Dating</a></li>
    </ul>
    <div class="blog-listing-section no-border" id="sports_id">
      <h1>Sports</h1>
      <div class="blog-listing-wrapper flex-mkt">
        <div class="blog-listing-block col-sm-3" *ngFor="let block of blocks2; index as i">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="{{block.src}}" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>

      </div></div>
    <div class="blog-listing-section no-border">
      <h1>Dating</h1>
      <div class="blog-listing-wrapper flex-mkt">
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
      </div></div>
    <div class="blog-listing-section no-border" id="lifestyle_id">
      <h1>Lifestyle</h1>
      <div class="blog-listing-wrapper flex-mkt">
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
        <div class="blog-listing-block col-sm-3">
          <a [routerLink]="['/blog-details']">
            <figure>
              <img src="assets/images/blog-listing-image.png" alt="">
            </figure>
            <div class="blog-detail-listing">
              <div class="info-block">
                <span>June 3, 2018</span>
                <span class="info-cat">Sports</span>
              </div>
              <p>Nulla a consequat, massa odio sit quis sagittis. Felis sed eget volutpat et in dolor.</p>
              <span class="by-block">By <a href="#">Jenelia Desoza</a></span>
            </div>
          </a>
        </div>
      </div></div>
  </div>
</div>

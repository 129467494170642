
<div id="wrapper" *ngIf="userData">
  <section class="profile-detail-section">
    <div class="container">
      <div class="profile-detail-wrapper common-wrapper row">
        <div class="col-sm-5">
          <figure><img src='{{userData?.profileImage}}' onerror="this.src='assets/images/mask-group.jpg';" alt=""></figure>
        </div>
        <div class="col-sm-7 profile-right-block">
          <div class="share-block flex-mkt">
            <h1>{{userData.name}}</h1>
            <ul class="wishlist-wrapper" >
              <!-- *ngIf="!this.likeButtonHide" -->
              <li *ngIf="!this.likeButtonHide"><a (click)="likeProfile()" ><img src="assets/images/like-image.png" alt=""></a></li>
              <li *ngIf="this.likeButtonHide"><a  ><img src="assets/images/likedImage.jpg" alt=""></a></li>
             
              <li *ngIf="isRated==1"><a class="rating-anchr" data-toggle="modal" data-target="#rateUser"><img src="assets/images/message-image.png" alt=""></a></li>
              <!-- <li><a  class="rating-anchr"><img src="assets/images/message-image.png" alt=""></a></li> -->
            </ul>
          </div>
          <div class="profile-info">
            <span>Noida,India</span>
            <!-- <ngx-star-rating style="float: left;" [id]="'rating'"></ngx-star-rating> -->
            <figure style="margin-top: 12px;" *ngFor="let item of [].constructor(avgRating);">
              <img src="assets/images/color-star.png">
            </figure>
            <!-- <a >Give Rating</a> -->
          </div>
          <p><img class="first-arrow" src="assets/images/top-comma.png" >{{userData.about}}<img class="last-arrow" src="assets/images/bottom-comma.png" ></p>
          <div class="interest-block">
            <span>Interest in</span>
            <ul>
              <li *ngFor="let intst of interest">
                <a >{{intst}}</a>
              </li>
            </ul>
          </div>
          <!-- <div class="social-block">
            <span>Social Links</span>
            <ul>
              <li><a ><img src="assets/images/instagram-image.png" alt=""></a></li>
            </ul>
          </div> -->
          <div class="social-block">
            <ul class="profile-report">
              <li><a  class="report-anchr">Report</a></li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <li><a >Share Profile</a></li>
            </ul>
            </div>
         
        </div>
      </div>

      


    <div class="modal fade" id="rateUser" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal_center">
      <div class="modal-content">

        <div class="">
          <div class="book-block report-block">
            <span class="book-close" #closeUserModal data-dismiss="modal"><img
                src="assets/images/close.png"></span>
            <h2>User Rating</h2>
            <form [formGroup]="userRatingForm">
                <div class="form-group">
                  <ngx-star-rating style="float: left;" formControlName="rating" [id]="'rating1-'"></ngx-star-rating>
                </div>
              <div class="form-group">
                <textarea placeholder="Your Reviews" formControlName="message" cols="15" rows="5"></textarea>
              </div><br>

              <div class="submit-block">
                  <button type="submit" [disabled]='!userRatingForm.valid' (click)="rateUser()">Submit</button>
                </div>

            </form>
            
          </div>
        </div>

      </div>
    </div>
  </div>




    </div>

    <!-- <div class="rating-popup common-popup">
      <div class="report-block">
        <span class="rating-close common-close"><img src="assets/images/close.png"></span>
        <h2>Give Rating</h2>
        <form>
          <span>Are you sure. you want to rate this profile?</span>
          <div class="form-group">
            <div class="select-wrap">
              <select>
                <option>1 star</option>
                <option>2 star</option>
                <option>3 star</option>
                <option>4 star</option>
                <option>5 star</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <textarea cols="30" rows="10" placeholder="Write the description"></textarea>
          </div>
        </form>
        <div class="report-anchor">
          <a >No Thanks</a>
          <a  class="yes-anchor">Rating</a>
        </div>
      </div>
    </div> -->


    <div class="report-wrapper common-popup">
      <div class="report-block">
        <span class="report-close common-close"><img src="assets/images/close.png"></span>
        <h2>Send Report</h2>
        <form>
          <span>Are you sure. you want to report this profile?</span>
          <div class="form-group">
            <textarea cols="30" rows="10" placeholder="Write the description"></textarea>
          </div>
        </form>
        <div class="report-anchor">
          <a >No Thanks</a>
          <a  class="yes-anchor">Yes</a>
        </div>
      </div>
    </div>
  </section>
  <!-- profile-detail-block-->

  <section class="instagram-profile" *ngIf="userPhotos?.length>0">
    <div class="container">
      <h1>Photo</h1>
      <div class="instagram-wrapper flex-mkt">
        <div class="instagram-block" *ngFor="let photos of userPhotos">
          <a >
            <figure><img src="{{photos}}" alt="profile-img"></figure>
          </a>
        </div>
      </div>
    </div>
  </section>

</div>

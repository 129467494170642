import { Component, OnInit } from '@angular/core';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
})
export class SubscriptionComponent implements OnInit {
  profiles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; // change elements with real data
  productData: any;

  constructor(
    private httpService: HttpService
  ) { }

  ngOnInit(): void {
    this.getSubscription();
  }

  getSubscription() {
    this.httpService.httpRequest(`user/getProduct`, HttpVerb.GET).
      subscribe((response: any) => {
        this.productData = response.responseData;
        console.log(this.productData);
      })
  }

  buySubscription(priceId){
    this.httpService.httpRequest(`user/subscription?priceId=${priceId}`, HttpVerb.GET).
      subscribe((response: any) => {
        if(response.responseCode == 200){
          localStorage.setItem("subscription", "1234567");
          window.open(response.responseData.paymentUrl,"_self");
        }
      })
  }
}

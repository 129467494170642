<div class="common-wrapper">
    <div class="container">
        <div class="photo-upload-wrapper common-head">
            <h1>Chat</h1>
            <ul class="tabs">
                <li><a (click)="chatBox()" target="_self">Message</a></li>
                <li><a (click)="chatBox()" target="_self">Pro</a></li>
            </ul>
        </div>
        <div id="demo" class="container">
            <div class="tabs-content">
                <div id="demo-tab-1" *ngIf="showChatBox" class="tabs-panel" style="display:block">
                    <div class="new-match">
                        <h2>New Matches</h2>
                        <ul class="profile-pic">
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                            <li><a href="#" target="_self"><img src="assets/images/chat-one.png" alt="" /></a></li>
                        </ul>
                    </div>
                    <div class="caht-name">
                        <div class="row flex-mkt">
                            <div class="col-xs-12 col-sm-3 rmk-chat">
                                <ul class="chat-mta">
                                    <li>
                                        <a href="#" target="_self">
                                            <img src="assets/images/chat-one.png" alt="" />
                                            <div class="profile-mtb">
                                                <h3 class="name">Adelaide Malone</h3>
                                                <p class="content">Hi, simran how are you.</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_self">
                                            <img src="assets/images/chat-one.png" alt="" />
                                            <div class="profile-mtb">
                                                <h3 class="name">Adelaide Malone</h3>
                                                <p class="content">Hi, simran how are you.</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_self">
                                            <img src="assets/images/chat-one.png" alt="" />
                                            <div class="profile-mtb">
                                                <h3 class="name">Adelaide Malone</h3>
                                                <p class="content">Hi, simran how are you.</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_self">
                                            <img src="assets/images/chat-one.png" alt="" />
                                            <div class="profile-mtb">
                                                <h3 class="name">Adelaide Malone</h3>
                                                <p class="content">Hi, simran how are you.</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_self">
                                            <img src="assets/images/chat-one.png" alt="" />
                                            <div class="profile-mtb">
                                                <h3 class="name">Adelaide Malone</h3>
                                                <p class="content">Hi, simran how are you.</p>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_self">
                                            <img src="assets/images/chat-one.png" alt="" />
                                            <div class="profile-mtb">
                                                <h3 class="name">Adelaide Malone</h3>
                                                <p class="content">Hi, simran how are you.</p>
                                            </div>
                                        </a>

                                    </li>
                                </ul>
                                <div class="bubble-wraper">
                                    <div class="bubble">
                                        <div class="ellipsis dot_1"></div>
                                        <div class="ellipsis dot_2"></div>
                                        <div class="ellipsis dot_3"></div>
                                    </div>
                                    <ul>
                                        <li><a href="#">Share Profile</a></li>
                                        <li><a href="#" class="report-anchr">Report</a></li>
                                        <li><a href="#" class="report-unmatch">Unmatch</a></li>
                                    </ul>
                                </div>
                                
                            </div>
                            <div class="col-xs-12 col-sm-9 custom-chat">
                                <div class="chat-header clearfix">
                                    <div class="chat-about">
                                        <img src="assets/images/chat-one.png" alt="" />
                                        <h3 class="name">Adelaide Malone</h3>
                                    </div>

                                </div>
                                <main class="msger-chat">
                                    <div class="msg left-msg">
                                        <div class="msg-text">
                                            how are you.
                                        </div>
                                    </div>

                                    <div class="msg right-msg">
                                        <div class="msg-text">
                                            Fine
                                        </div>
                                    </div>
                                    <div class="msg left-msg">
                                        <div class="msg-text">
                                            Are your know. what is my favouritedress.
                                        </div>
                                    </div>

                                    <div class="msg right-msg">
                                        <div class="msg-text">
                                            No, are you know about my favourite?
                                        </div>
                                    </div>
                                </main>
                                <form class="msger-inputarea">
                                    <div class="msger-inputa">
                                        <a href="#" class="smil-icon" target="_self"><img src="assets/images/smile-icon.png" alt="" /></a>
                                        <input type="text" class="msgers-input" placeholder="Type here...">
                                        <a href="#" class="attch-icon" target="_self"><img src="assets/images/attachtment-icon.png" alt="" /></a>
                                        <a href="#" class="cmra-icon" target="_self"><img src="assets/images/camera-icon.png" alt="" /></a>
                                    </div>
                                    <button type="submit" class="msger-send-btn">Send</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--caht-name-->
                </div>
                <div id="demo-tab-2" *ngIf="!showChatBox"  class="tabs-panel">
                    <div class="caht-name">
                        <div class="row flex-mkt">
                            <div class="col-xs-12 col-sm-3 rmk-chat">
                                <ul class="chat-mta">
                                    <li>
                                        <a href="#" target="_self">
                                            <img src="assets/images/chat-one.png" alt="" />
                                            <div class="profile-mtb">
                                                <h3 class="name">Adelaide Malone</h3>
                                                <p class="content">Hi, simran how are you.</p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <div class="bubble-wraper">
                                    <div class="bubble">
                                        <div class="ellipsis dot_1"></div>
                                        <div class="ellipsis dot_2"></div>
                                        <div class="ellipsis dot_3"></div>
                                    </div>
                                    <ul>
                                        <li><a href="#">Share Profile</a></li>
                                        <li><a href="#" class="report-anchr">Report</a></li>
                                        <li><a href="#" class="report-unmatch">Unmatch</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-9 custom-chat">
                                <div class="chat-header clearfix">
                                    <div class="chat-about">
                                        <img src="assets/images/chat-one.png" alt="" />
                                        <h3 class="name">Adelaide Malone</h3>
                                    </div>
                                    <div class="chat-icon">
                                        <a href="#" class="rating-anchr"><img src="assets/images/message-chat.png" alt=""></a>
                                        <a href="#"><img src="assets/images/wishligt.png" alt="" /></a>
                                    </div>
                                </div>
                                <main class="msger-chat">

                                </main>
                                <form class="msger-inputarea">
                                    <div class="msger-inputa">
                                        <a href="#" class="smil-icon" target="_self"><img src="assets/images/smile-icon.png" alt="" /></a>
                                        <input type="text" class="msgers-input" placeholder="Type here...">
                                        <a href="#" class="attch-icon" target="_self"><img src="assets/images/attachtment-icon.png" alt="" /></a>
                                        <a href="#" class="cmra-icon" target="_self"><img src="assets/images/camera-icon.png" alt="" /></a>
                                    </div>
                                    <button type="submit" class="msger-send-btn">Send</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--caht-name-->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="report-wrapper common-popup">
<div class="report-block">
    <span class="report-close common-close"><img src="assets/images/close.png"></span>
    <h2>Send Report</h2>
    <form>
        <span>Are you sure. you want to report this profile?</span>
        <div class="form-group">
            <textarea cols="30" rows="10" placeholder="Write the description"></textarea>
        </div>
        
    </form>
    <div class="report-anchor">
        <a href="#" class="report-close">No Thanks</a>
        <a href="#" class="yes-anchor">Yes</a>
    </div>
</div>
</div>
<div class="popup-unmatch common-popup">
<div class="report-block">
    <span class="report-close common-close"><img src="assets/images/close.png"></span>
    <h2>Unmatch Profile</h2>
    <form>
        <span>Are you sure. you want to report this profile?</span>
        <div class="form-group">
        </div>
    </form>
    <div class="report-anchor">
        <a href="#" class="report-close">No Thanks</a>
        <a href="#" class="yes-anchor">Yes</a>
    </div>
</div>
</div>

<div class="rating-popup common-popup">
<div class="report-block">
    <span class="rating-close common-close"><img src="assets/images/close.png"></span>
    <h2>Give Rating</h2>
    <form>
        <span>Are you sure. you want to rate this profile?</span>
        <div class="form-group">
            <div class="select-wrap">
                <select>
                    <option>1 star</option>
                    <option>2 star</option>
                    <option>3 star</option>
                    <option>4 star</option>
                    <option>5 star</option>
                </select>
            </div>
        </div>
        <div class="form-group">
            <textarea cols="30" rows="10" placeholder="Write the description"></textarea>
        </div>
    </form>
    <div class="report-anchor">
        <a href="#" class="rating-close">No Thanks</a>
        <a href="#" class="yes-anchor">Rating</a>
    </div>
</div>		
</div>

import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, retry} from "rxjs/operators";
import {HttpService} from "../http/http.service";
const TOKEN_HEADER_KEY = 'authToken';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  accessToken: any;
  constructor(private injector:Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  let httpService = this.injector.get(HttpService);
  let token = httpService.getToken()
  if(token){
    this.accessToken = token
    // console.log(this.accessToken);
  }else{this.accessToken = ''}
  const authRequest = request.clone({
    setHeaders:{
      accessToken: this.accessToken,
      authorization: 'Basic ZmxpbmdzLW5vZGU6ZmxpbmdzLW5vZGU='
    }
  })

    return next.handle(authRequest)
    //   .pipe(retry(1),catchError((error:HttpErrorResponse) => {
    //
    // }));
  }
}

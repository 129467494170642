<div id="wrapper">

	<div class="common-wrapper">
		<div class="container">
			<div class="common-head">
				<h2>Hide Professional</h2>
				<a type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal1">Book Now</a>
			</div>
			<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal_center">
					<div class="modal-content">

						<div class="">
							<div class="book-block report-block">
								<span class="book-close" #closeModal data-dismiss="modal"><img
										src="assets/images/close.png"></span>
								<h2>Book Professional</h2>
								<form [formGroup]="bookingForm">
									<!-- <div class="form-group">
										<label>Date</label><br><br>
										<ngx-datepicker formControlName="date"></ngx-datepicker>
										</div> -->

									<!-- <div class="form-group has-feedback">
										<label>Date</label><br><br>
										<input class="form-control" style="height: 0%;" placeholder="Select Date"
											name="dp" formControlName="date"  ngbDatepicker #d="ngbDatepicker">
										<i class="fa fa-calendar" (click)="d.toggle()"></i>
									</div> -->
									<div class="form-group">
										<div class="input-group">
										  <input class="form-control" placeholder="Select Date"
												 name="dp" ngbDatepicker  formControlName="date"  #d="ngbDatepicker"  [minDate]="minDate1" [maxDate]="maxDate1">
										  <div class="" style="margin-left: 10px;">
											<i class="fa fa-calendar" (click)="d.toggle()"></i>
											<!-- <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button> -->
										  </div>
										</div>
									  </div>

									<div class="form-group">
										<!-- <input type="text" placeholder="Time"> -->
										<label>Time</label><br><br>
										<input formControlName="time"  id="appt-time"
										type="time"
										name="appt-time"
										min="12:00"
										max="18:00"
										required
										pattern="[0-9]{2}:[0-9]{2}" />
									</div><br>
									<!-- <div class="submit-block">
										<button type="submit" [disabled]='!bookingForm.valid' (click)="Booking()">Proceed To Payment</button>
									</div> -->
									<div class="report-anchor">
										<a data-dismiss="modal">No Thanks</a>
										<a (click)="Booking()" class="yes-anchor">Proceed To Payment</a>
									</div>
								</form>

							</div>
						</div>

					</div>
				</div>
			</div>


			<p><img class="first-arrow" src="assets/images/top-comma.png"> Kogi Cosby sweater ethical squid irony
				disrupt,
				organic tote bag gluten free XOXO wolf typewriter mixtape, Kogi Cosby sweater ethical squid irony
				disrupt,
				organic tote bag gluten free XOXO wolf typewriter mixtape.<img class="last-arrow"
					src="assets/images/bottom-comma.png"></p>
			<div class="instagram-profile">
				<h1>Company Past Shoots</h1>
				<div class="instagram-wrapper flex-mkt">
					<div class="instagram-block" *ngFor="let photos of photoShoots">
						<a>
							<figure><img src="{{photos}}" alt="profile-img"></figure>
						</a>
					</div>
				</div>

			</div>
			<section class="shoot-section">
				<h1>Type Of Shoots</h1>
				<div class="shoot-wrapper flex-mkt">
					<div class="shoot-block col-sm-6" *ngFor="let types of shootTypes">
						<a>
							<figure>
								<img src="{{types.image}}" alt="">
							</figure>
							<h3>{{types.title}}</h3>
						</a>
					</div>
					<!-- <div class="shoot-block col-sm-6">
		            <a>
		              <figure>
		                <img src="assets/images/shoot-image.png" alt="">
		              </figure>
		              <h3>Lorem Ipsum</h3>
		            </a>
		          </div>
		          <div class="shoot-block col-sm-6">
		            <a>
		              <figure>
		                <img src="assets/images/shoot-image.png" alt="">
		              </figure>
		              <h3>Lorem Ipsum</h3>
		            </a>
		          </div>
		          <div class="shoot-block col-sm-6">
		            <a>
		              <figure>
		                <img src="assets/images/shoot-image.png" alt="">
		              </figure>
		              <h3>Lorem Ipsum</h3>
		            </a>
		          </div> -->
				</div>
			</section>


		</div>
	</div>
	<!-- *ngFor="let rate of professionalRating" -->
	<section class="rating-section">
		<div class="container">
			<h1>Rating and Reviews</h1>
			<div class="rating-block flex-mkt" style="cursor: pointer;"
				[routerLink]="['/swipe-detail', rate?.userId?._id]" *ngFor="let rate of professionalRating">
				<figure>
					<img src="{{rate?.userId?.profileImage}}" alt="">
				</figure>
				<div class="rating-details">
					<h2>{{rate?.userId?.name}}</h2>
					<div class="row">
						<figure style="margin-inline-end: -6px;" *ngFor="let star of rate?.rating">
							<img src="assets/images/color-star.png">
						</figure>
					</div>
					<p>{{rate?.message}}</p>
				</div>
			</div>
		</div>
	</section>

	<section class="agent-section">
		<div class="container">
			<div class="common-head">
				<h2>Get Agents</h2>
				<a type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal3">${{pricing}}
					get agent</a>
				<!-- <a>$<span>20</span> get agent</a> -->
			</div>
			<div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal_center">
					<div class="modal-content">
						<div class="">
							<div class="book-block report-block">
								<span class="book-close" data-dismiss="modal"><img src="assets/images/close.png"></span>
								<h2>Get Agent</h2>
								<form [formGroup]="agentForm">
									<!-- <div class="form-group">
										<label>Start Date</label><br><br>
										<input class="form-control" 
											style="height: 0%;" placeholder="Select Date" name="sdp"
											formControlName="startDate" ngbDatepicker #sd="ngbDatepicker">
										<button class="btn btn-outline-secondary calendar" (click)="sd.toggle()"
											type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
									</div> -->
									<div class="form-group">
										<div class="input-group">
										  <input class="form-control" (dateSelect)="changeStratDate($event)" placeholder="Select Date"
										  name="d2" ngbDatepicker formControlName="startDate" ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate2" [maxDate]="maxDate2">
										  <div class="" style="margin-left: 10px;">
											<i class="fa fa-calendar" (click)="d2.toggle()"></i>
										  </div>
									
									 <!-- <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button> -->
								   </div>
										</div>
									


									<div class="form-group">
										<label>End Date</label><br><br>
										<input disabled="true" formControlName="endDate">
									</div><br>
									<!-- <div class="submit-block">
									<button type="submit" [disabled]='!agentForm.valid' (click)="getAgent()">Proceed To Payment</button>
								</div> -->
									<div class="report-anchor">
										<a data-dismiss="modal">No Thanks</a>
										<a (click)="getAgent()" class="yes-anchor">Proceed To Payment</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>


			<p><img class="first-arrow" src="assets/images/top-comma.png">
				{{description}}
				<img class="last-arrow" src="assets/images/bottom-comma.png">
			</p>
			<div class="pricing-block">
				<h1>Pricing</h1>
				<div class="price"><span>$</span>{{pricing}}</div>
			</div>
		</div>
	</section>

	<section class="rating-section agent-after-block">
		<div class="container">
			<h1>Rating and Reviews</h1>
			<div class="rating-block flex-mkt" style="cursor: pointer;" *ngFor="let rate of agentRating"
				[routerLink]="['/swipe-detail', rate?.userId?._id]">
				<figure>
					<img src="{{rate?.userId?.profileImage}}" alt="">
				</figure>
				<div class="rating-details">
					<h2>{{rate?.userId?.name}}</h2>
					<!-- <figure>
						<ngx-star-rating value=5 [id]="'rating'"></ngx-star-rating>
					</figure> -->
					<!-- <app-star-rating [rating]="2" [isEditable] = "true"></app-star-rating>  -->
					<div class="row">
						<figure style="margin-inline-end: -6px;" *ngFor="let star of rate?.rating">
							<img src="assets/images/color-star.png">
						</figure>
					</div>
					<p>{{rate?.message}}</p>
				</div>
			</div>
		</div>
	</section>

</div>
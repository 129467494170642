import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { WalletComponent } from './components/wallet/wallet.component';
import { BookingComponent } from './components/booking/booking.component';
import { ExploreComponent } from './components/explore/explore.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { ProfileComponent } from './components/profile/profile.component';
import { HireComponent } from './components/hire/hire.component';
import { NearmeComponent } from './components/nearme/nearme.component';
import {MyAgentComponent} from "./components/my-agent/my-agent.component";
import {LegalPolicyComponent} from "./components/legal-policy/legal-policy.component";
import {TncComponent} from "./components/tnc/tnc.component";
import {AgentDetailsComponent} from "./components/my-agent/agent-details/agent-details.component";
import {VideoComponent} from "./components/video/video.component";
import { ChatComponent } from './components/chat/chat.component';

const routes: Routes = [
  { path: 'wallet', component: WalletComponent },
  { path: 'booking', component: BookingComponent },
  { path: 'chat', component: ChatComponent },
  { path: 'explore', component: ExploreComponent },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'my-agent', component: MyAgentComponent },
  { path: 'agent-details/:booking_id', component: AgentDetailsComponent },
  { path: 'hire-professional', component: HireComponent },
  { path: 'nearme', component: NearmeComponent },
  { path: 'video', component: VideoComponent },
  { path: 'privacy-policy', component: LegalPolicyComponent },
  { path: 'terms-and-condition', component: TncComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FlingsRoutingModule {}

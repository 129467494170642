import { Component, OnInit } from '@angular/core';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay ,Mousewheel} from 'swiper/core';
import Swiper from "swiper/core";
declare var $: any;
// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay,Mousewheel]);
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
slides = [`<img src="../../../../../assets/images/shoot-image.png">`,`<img src="../../../../../assets/images/shoot-image.png">`,`<img src="../../../../../assets/images/shoot-image.png">`,`<img src="../../../../../assets/images/shoot-image.png">`,`<img src="../../../../../assets/images/shoot-image.png">`,]
  constructor() { }

  ngOnInit(): void {

  }

}

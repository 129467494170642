import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {LayoutModule} from "../../shared/layout/layout.module";
import {SwiperModule} from "swiper/angular";

@NgModule({
  declarations: [DashboardComponent],
  imports: [CommonModule, HomeRoutingModule, LayoutModule, SwiperModule],
})
export class HomeModule {}

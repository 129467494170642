import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpService} from "../../../core/services/http/http.service";
import {HttpVerb} from "../../../core/services/enum/const.enum";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('exampleModal2') exampleModal2: ElementRef;
  show: boolean;
  message: any;
  showNoti: boolean = false;
  constructor(public httpService:HttpService,
    private route: Router, private toastr: ToastrService) {}

  ngOnInit(): void {
    // window.addEventListener('scroll', () => {
    //   if (window.scrollY > 200) {
    //      // this.show = false;
    //   document.getElementsByTagName('header')[0].setAttribute('class', 'nav__black')
    //     // console.log(this.show)
    //   } else if (window.scrollY < 200) {
    //     document.getElementsByTagName('header')[0].setAttribute('class', 'header')
    //
    //   }
    // });
    //  return () => {
    //      window.removeEventListener("scroll");
    //  }
    // this.httpService.receiveMessage;
    this.message = this.httpService.currentMessage;
    // console.log(this.message);
  }
  logOut(): void{
    this.httpService.removeToken();
    this.route.navigate(['/home']);
    this.httpService.httpRequest(`user/logout`, HttpVerb.POST,{}).
      subscribe((response: any) => {
        console.log(response);
      })
  }

  openAgent(){
    if(this.httpService.getToken()){
      this.route.navigate(['/dating/my-agent'])
    }else{
      this.exampleModal2.nativeElement.click();
    }
  }
  openProfessional(){
    // if(this.httpService.getToken()){
      this.route.navigate(['/dating/hire-professional'])
    // }else{
    //   this.exampleModal2.nativeElement.click();
    // }
  }
  openBlog(){
    // if(this.httpService.getToken()){
      this.route.navigate(['/blog'])
    // }else{
    //   this.exampleModal2.nativeElement.click();
    // }
  }
  openSwipe(){
    if(this.httpService.getToken()){
      this.route.navigate(['/swipe'])
    }else{
      this.exampleModal2.nativeElement.click();
    }
  }
  openVideos(){
    // if(this.httpService.getToken()){
      this.route.navigate(['/dating/video'])
    // }else{
    //   this.exampleModal2.nativeElement.click();
    // }
  }

  showNotifi(){
    this.showNoti = !this.showNoti;
  }

getSubscriptionMessage(){
  if(this.httpService.getToken()){
    this.route.navigate(['/dating/subscription']);
  }else{
    this.toastr.error("Please login into the system to see this information","",{
      timeOut: 3500,
    });
  }
 

}

}

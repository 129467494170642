<div id="wrapper">

  <div class="common-wrapper">
    <div class="contianer">
      <div class="thank-block">
        <figure>
          <img src="assets/images/thank-image.png" alt="">
        </figure>
        <h1>Your plan is Successfully activated</h1>
        <span>Lorem ipsum dolor is amet.</span>
      </div>
    </div>
  </div>
</div>

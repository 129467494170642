import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';
import * as moment from 'moment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profileForm: FormGroup;
  changeForm: FormGroup;
  currentUser: any;
  userID: string;
  show: boolean = false;
  fileData: File;
  formData = new FormData();
  userData: any;
  intrst = [];
  interested = [];
  saveInterst: string;
  userPhotos = [];
  profileImage: string;
  profileName: string;
  today = new Date();
  latitude: any;
  longitude: any;
  avgRating: any;
  constructor(private fb: FormBuilder,
    private httpService: HttpService,
    private toastr: ToastrService) { }

  showMe = [
    { type: 'Male', img: 'assets/images/mail.png', val: '0' },
    { type: 'Female', img: 'assets/images/gender-image.png', val: '1' },
    { type: 'Others', img: 'assets/images/mail.png', val: '2' }
  ]


  interest = [{ name: 'Hook up', value: "0", status: false },
  { name: 'Friendship', value: "1", status: false },
  { name: 'Dating', value: "2", status: false },
  { name: 'Vulgar chat', value: '3', status: false }];
  // HairPreference = [
  //   {type: 'Red', img: 'assets/images/mail.png'},
  //   {type: 'Brown', img: 'assets/images/gender-image.png'},
  //   {type: 'Black', img: 'assets/images/mail.png'}
  // ]

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.userID = this.currentUser._id;
    this.userProfile();
    this.getProfile();
    this.changeForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
    })
    let abc = moment("2021115").format('YYYYMMDD');
    console.log(abc);
  }

  userProfile = () => {
    this.profileForm = this.fb.group({
      profileImage: ['', ''],
      name: ['', [Validators.required]],
      about: ['', ''],
      gender: ['', ''],
      language: ['', ''],
      sexualOrientation: ['', ''],
      genderPreference: ['', ''],
      hairColor: ['', ''],
      hairPreference: ['', ''],
      dob: ['', ''],
      height: ['', ''],
      heightPreference: ['', ''],
      weight: ['', ''],
      weightPreference: ['', ''],
      gifts: ['', ''],
      distance: ['', ''],
      socialLink: ['', ''],
      interest: ['', '']
    })
  }

  getProfile() {
    this.httpService.httpRequest(`user/getUserProfile`, HttpVerb.GET, this.userID).
      subscribe((response: any) => {
        console.log(response);
        this.userData = response.responseData;
        this.latitude = this.userData.gps[0];
        this.longitude = this.userData.gps[1];
        let location = this.httpService.displayLocation(this.latitude, this.longitude);
        console.log(location);
        this.profileName = this.userData.name;
        this.profileImage = this.userData.profileImage
        this.intrst = this.userData.interest.split(",");
        let stringDate = this.userData.dob.toString();
        let newDOB = moment(stringDate).format();
        // console.log(newDate);
        // this.interest = [];
        this.userPhotos = this.userData.photos;
        this.avgRating = this.userData.avgRating;
        this.getInterest();
        this.profileForm.patchValue({
          name: this.userData.name,
          about: this.userData.about,
          gender: this.userData.gender,
          genderPreference: parseInt(this.userData.genderPreference),
          dob: newDOB,
          hairColor: this.userData.hairColor,
          hairPreference: this.userData.hairPreference,
          sexualOrientation: this.userData.sexualOrientation,
          distance: this.userData.distance,
          height: this.userData.height,
          heightPreference: this.userData.heightPreference,
          weight: this.userData.weight,
          weightPreference: this.userData.weightPreference,
        })
      })
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.fileData = event.target.files[0] as File;
      this.formData.append('photos', this.fileData);
      this.httpService.httpRequest(`user/uploadPhotos`, HttpVerb.POST, this.formData).
        subscribe((response: any) => {
          console.log(response);
          if (response.responseCode == 200) {
            // this.userPhotos = response.responseData.userPhotos;
            this.toastr.success("Photo Added Successfully", "Profile Updated");
            setTimeout(() => {
              this.getProfile();
            },500);
          } else {
            this.toastr.error(response.responseMessage)
          }
        })
    }
  }

  getInterest() {
    this.intrst.forEach(x => {
      this.interest.forEach((y, i) => {
        if (x == y.value) {
          this.interest[i].status = true
        }
      })
    })
  }

  selectInterest() {
    let trueValue = this.interest
      .filter(opt => opt.status)
      .map(opt => opt.value)
    this.saveInterst = trueValue.toString();
    this.profileForm.get('interest').setValue(this.saveInterst);
    // console.log(this.interested);
  }

  onSubmit = () => {
    this.selectInterest();
    this.profileForm.value.gender = this.profileForm.value.gender.toString();
    this.profileForm.value.genderPreference = this.profileForm.value.genderPreference.toString();
    this.profileForm.value.hairColor = this.profileForm.value.hairColor.toString();
    this.profileForm.value.hairPreference = this.profileForm.value.hairPreference.toString();

    console.log(this.profileForm.value.dob);
    this.profileForm.value.dob = moment(this.profileForm.value.dob).format('YYYYMMDD');

    // this.profileForm.value.dob = year.toString() + month.toString() + day.toString();
    console.log(this.profileForm.value);
    this.httpService.httpRequest(`user/updateProfile`, HttpVerb.POST, this.profileForm.value).
      subscribe((response: any) => {
        console.log(response);
        if (response.responseCode == 200) {
          this.toastr.success("Your Profile Updated Successfully", "Profile Updated")
        } else {
          this.toastr.error(response.responseMessage);
        }
      })
  }

  changePassword() {
    console.log(this.changeForm.value);
    this.httpService.httpRequest(`user/changePassword`, HttpVerb.POST, this.changeForm.value).
      subscribe((response: any) => {
        console.log(response);
        if (response.responseCode == 200) {
          this.toastr.success("Your Password Updated Successfully", "Password Updated")
        } else {
          this.toastr.error(response.responseMessage)
        }
      })
  }

  onProfileChange(event) {

    if (event.target.files.length > 0) {
      // this.profileForm.controls.profileImage.setValue(null);
      this.fileData = event.target.files[0] as File;
      this.formData.append('uploadMedia', this.fileData);
      this.httpService.httpRequest(`user/getProfilePic`, HttpVerb.POST, this.formData).
      subscribe((response: any) => {
        console.log(response);
        // this.imageSrc = response.responseData.profileImage;
        if(response.responseCode==200)
        this.profileForm.controls.profileImage.setValue(response.responseData.profileImage);
        this.profileImage = response.responseData.profileImage;
      })
    }
  }

}


import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-swipe-detail',
  templateUrl: './swipe-detail.component.html',
  styleUrls: ['./swipe-detail.component.css']
})
export class SwipeDetailComponent implements OnInit {
  @ViewChild('closeUserModal') closeUserModal: ElementRef;
  userID: any;
  userData: any;
  userPhotos: any;
  interest = [];
  isRated: any;
  userRatingForm: FormGroup;
  avgRating: any;
  likeButtonHide:boolean=false;
  likedProfileByUsers:any=[];

  constructor(private route:ActivatedRoute,
    private httpService: HttpService,
    private toastr: ToastrService,
    private fb: FormBuilder) {

    
  }

  ngOnInit(): void {
    this.route.params.subscribe(param => {
      this.userID = param.id;
    })
    this.getProfile();

    this.userRatingForm = this.fb.group({
      user:['',''],
      rating: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });

    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
});
  }
  // ?userID=${this.userID}
  getProfile(){
    this.httpService.httpRequest(`user/getUserProfile?userID=${this.userID}`, HttpVerb.GET).
    subscribe((response: any) => {
      console.log(response);
      this.userData = response.responseData;
      this.userPhotos = this.userData.photos;
      this.interest = this.userData.interest?this.userData.interest.split(","):[];
      this.isRated  = this.userData.isRated;
      this.avgRating = this.userData.avgRating;
     
      this.likedProfileByUsers= this.userData.profilelikedByusers;
      this.hideLikeButton();
    })
  }

  likeProfile() {
  
    // console.log(user, likeType);
    let obj = {
      users: this.userID,
      likeType: 1
    }
    // console.log(obj);
    this.httpService.httpRequest(`user/likeProfile`, HttpVerb.POST, obj).
      subscribe((response: any) => {
        console.log(response);
        if (response.responseCode == 200) {
          this.toastr.success("Profile Liked");
        
          this.likeButtonHide=true;
        }else{
          this.toastr.warning(response.responseMessage);
        }
      })
    // this.swiper.slideNext();
    // this.swiper.navigation.nextEl;
  }

  rateUser(){
    // console.log(this.agentRatingForm.value)
    this.userRatingForm.value.user = this.userID;
    console.log(this.userRatingForm.value)
    this.httpService.httpRequest(`user/rateUser`, HttpVerb.POST, this.userRatingForm.value).
      subscribe((response: any) => {
        console.log(response);
        if(response.responseCode == 200){
          this.closeUserModal.nativeElement.click();
          this.toastr.success("Ratings Submitted");
        }
      })
  }
  hideLikeButton(){
    let currenctUserDetails=sessionStorage.getItem('currentUser');
    currenctUserDetails=JSON.parse(currenctUserDetails)
    console.log("===>current user details",currenctUserDetails,"=======>",currenctUserDetails['_id']);
    this.likedProfileByUsers.forEach(e=>{
      if(e.userId==currenctUserDetails['_id']){
        this.likeButtonHide=true;
      }
    })

  }

}

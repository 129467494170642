<nav class="navbar navbar-expand-sm sticky-top navbar-light bg-light">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03"
    aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" [routerLink]="['/home']"> <img style="height: 9vh" src="assets/images/logo.png" alt="" /></a>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
    <ul class="navbar-nav mr-auto mt-3 mt-lg-0 ">
      <li class="nav-item active">
          <a class="nav-link" style="cursor: pointer" (click)="openAgent()">My Agent</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer" (click)="openProfessional()">Hire Professional</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer" (click)="openSwipe()">Swipe</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer" (click)="openBlog()">Blog</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer" (click)="openVideos()">Video</a>
      </li>
      <li class="nav-item">
        <span class="nav-link ml-2"></span>
      </li>

      <li class="nav-item">
        <span class="nav-link">|</span>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="assets/images/fa-solid_globe-americas.png" alt="" />
          <!-- {{ message | async | json }} -->
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item pointer">English</a>
          <a class="dropdown-item pointer">French</a>
          <div class="dropdown-item pointer">German</div>
          <a class="dropdown-item pointer">Persian</a>
        </div>
      </li>
      <li class="nav-item">
        <span class="nav-link">|</span>
      </li>
      <li class="nav-item">
        <span class="nav-link" style="cursor: pointer" (click)="showNotifi()">
          <img src="assets/images/bell.png" alt="" />
          <span class="count">2</span>
        </span>
        <div *ngIf="showNoti" class="notification-wrapper">
          <h5>Notification</h5>
          <ul class="chat-mta notification">
            <li>
              <a target="_self">
                <img src="assets/images/chat-one.png" alt="">
                <div class="profile-mtb">
                  <h3 class="name">Adelaide Malone <time>2:30 PM</time></h3>
                  <p class="content">Kogi Cosby sweater ethical squid irony disrupt, organic tote bag gluten free .</p>
                </div>
              </a>
            </li>
            <li>
              <a target="_self">
                <img src="assets/images/chat-one.png" alt="">
                <div class="profile-mtb">
                  <h3 class="name">Adelaide Malone <time>2:30 PM</time></h3>
                  <p class="content">Kogi Cosby sweater ethical squid irony disrupt, organic tote bag gluten free .</p>
                </div>
              </a>
            </li>
            <li>
              <a target="_self">
                <img src="assets/images/chat-one.png" alt="">
                <div class="profile-mtb">
                  <h3 class="name">Adelaide Malone <time>2:30 PM</time></h3>
                  <p class="content">Kogi Cosby sweater ethical squid irony disrupt, organic tote bag gluten free .</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </li>

<!-- 
      <li class="notification-group nav-item">
        <form>
          <div class="form-group">
            <a>
              <img src="assets/images/bell.png" alt="" />
              <span class="count">2</span>
            </a>
          </div>
        </form>
        <div class="notification-wrapper">
          <h5>Notification</h5>
          <ul class="chat-mta notification">
            <li>
              <a target="_self">
                <img src="assets/images/chat-one.png" alt="">
                <div class="profile-mtb">
                  <h3 class="name">Adelaide Malone <time>2:30 PM</time></h3>
                  <p class="content">Kogi Cosby sweater ethical squid irony disrupt, organic tote bag gluten free .</p>
                </div>
              </a>
            </li>
            <li>
              <a target="_self">
                <img src="assets/images/chat-one.png" alt="">
                <div class="profile-mtb">
                  <h3 class="name">Adelaide Malone <time>2:30 PM</time></h3>
                  <p class="content">Kogi Cosby sweater ethical squid irony disrupt, organic tote bag gluten free .</p>
                </div>
              </a>
            </li>
            <li>
              <a target="_self">
                <img src="assets/images/chat-one.png" alt="">
                <div class="profile-mtb">
                  <h3 class="name">Adelaide Malone <time>2:30 PM</time></h3>
                  <p class="content">Kogi Cosby sweater ethical squid irony disrupt, organic tote bag gluten free .</p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </li> -->



      <li class="nav-item">
        <span class="nav-link">|</span>
      </li>
      <div *ngIf="this.httpService.getToken()">
        <li class="nav-item">
          <!--        <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
          <!--          My Account-->
          <!--        </a>-->
          <span class="nav-link">
            <form>
              <div class="form-group login">
                <!--                        <div class="login-wrper">-->
                <!--                          <p>Login</p>-->
                <div class="account-menu">
                  <span>My Account</span>
                  <ul>
                    <!--                              <button (click)="$event.preventDefault()" data-toggle="modal" data-target="#exampleModal2">login</button>-->
                    <li><a [routerLink]="['/dating/profile']">profile</a></li>
                    <li><a [routerLink]="['/dating/profile']">change password</a></li>
                    <!-- <li><a [routerLink]="['/dating/booking']">my bookings</a></li> -->
                    <li><a [routerLink]="['/dating/chat']">my chats</a></li>
                    <li><a [routerLink]="['/dating/explore']">explore</a></li>
                    <li><a [routerLink]="['/dating/subscription']">My subscription</a></li>
                    <!--                              <li><a [routerLink]="['/dating/profile']" >My Agent</a></li>-->
                    <li><a [routerLink]="['/dating/wallet']">My Wallet</a></li>
                    <li style="cursor: pointer"><a (click)="logOut()">logout</a></li>
                  </ul>
                </div>
                <!--                        </div>-->
              </div>
            </form>
          </span>
        </li>
      </div>

      <!--      <li class="nav-item">-->
      <!--        <span class="nav-link"> <a  class="btn btn-default sub-anchr">Get Subscription</a></span>-->
      <!--      </li>-->
      <!--      <li class="nav-item ">-->
      <!--        <span class="nav-link "> <a  class="btn btn-info register-anchr">Register</a></span>-->
      <!--      </li>-->
      <form *ngIf="!this.httpService.getToken()" class="form-inline my-2 ml-2 my-lg-0">
        <!--      <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">-->
        <button class="btn btn-default sub-anchr mr-2 subscriptionbtn" (click)="$event.preventDefault();getSubscriptionMessage()"
          >Get Subscription</button>
        <button class="btn   registerbtn" (click)="$event.preventDefault()" data-toggle="modal"
          data-target="#exampleModal">Register</button>
        <button class="btn   registerbtn" (click)="$event.preventDefault()" #exampleModal2 data-toggle="modal"
          data-target="#exampleModal2">Login</button>
      </form>
    </ul>

  </div>

</nav>


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal_center">
    <div class="modal-content">

      <div class="body">
        <app-register></app-register>
        <!-- <app-login *ngIf="show"></app-login> -->
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal_center">
    <div class="modal-content">
      <app-login></app-login>
      <div>
      </div>
    </div>
  </div>
</div>


<!--Header Section End-->

<div id="wrapper">
  <div class="common-wrapper agents-section">
    <div class="container">
      <div class="photo-upload-wrapper common-head">
        <h1>My Agents</h1>
        <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#rateAgent">Rate Service</a>
      </div>

      <div class="modal fade" id="rateAgent" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal_center">
        <div class="modal-content">

          <div class="">
            <div class="book-block report-block">
              <span class="book-close" #closeAgentModal data-dismiss="modal"><img
                  src="assets/images/close.png"></span>
              <h2>Rate Agent Service</h2>
              <form [formGroup]="agentRatingForm">
                <!-- <div class="form-group">
                  <label>Date</label><br><br>
                  <ngx-datepicker formControlName="date"></ngx-datepicker>
                  </div> -->

                  <div class="form-group">
                    <!-- <label>Rating</label><br><br> -->
                    <ngx-star-rating style="float: left;" formControlName="rating" [id]="'rating'"></ngx-star-rating>
                  </div>
                <div class="form-group">
                  <textarea placeholder="Your Reviews" formControlName="message" cols="15" rows="5"></textarea>
                </div><br>

                <div class="submit-block">
										<button type="submit" [disabled]='!agentRatingForm.valid' (click)="rateAgentService()">Submit</button>
									</div>

                <!-- <div class="report-anchor">
                  <a data-dismiss="modal">No Thanks</a>
                  <a (click)="Booking()" class="yes-anchor">Submit</a>
                  </div> -->
              </form>
              
            </div>
          </div>

        </div>
      </div>
    </div>


      <div class="agent-wrapper flex-mkt">
        <div class="agents-block col-sm-6" *ngFor="let agent of agentList">
          <table>
            <tr>
              <td>Start Date:</td>
              <td><span>{{agent.startDate}}</span></td>
            </tr>
            <tr>
              <td>End Date:</td>
              <td><span>{{agent.endDate}}</span></td>
            </tr>
            <tr>
              <td>Status:</td>
              <td><span class="ongoing-block">{{agent.status}}</span></td>
            </tr>
          </table>
          <a [routerLink]="['/agent-details/', agent.bookingId]">View Details</a>
        </div>
      </div>
    </div>
  </div>

  <div class="booking-section">
    <div class="container">
      <div class="photo-upload-wrapper common-head">
        <h1>My Bookings</h1>
        <a type="button" class="btn btn-primary" data-toggle="modal" data-target="#rateProfessional">Rate Service</a>
      </div>

      <div class="modal fade" id="rateProfessional" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal_center">
        <div class="modal-content">

          <div class="">
            <div class="book-block report-block">
              <span class="book-close" #closeProfessionalModal data-dismiss="modal"><img
                  src="assets/images/close.png"></span>
              <h2>Rate Professional Service</h2>
              <form [formGroup]="professionalRatingForm">
                <!-- <div class="form-group">
                  <label>Date</label><br><br>
                  <ngx-datepicker formControlName="date"></ngx-datepicker>
                  </div> -->

                  <div class="form-group">
                    <!-- <label>Rating</label><br><br> -->
                    <ngx-star-rating style="float: left;" formControlName="rating" [id]="'rating1'"></ngx-star-rating>
                  </div>
                <div class="form-group">
                  <textarea placeholder="Your Reviews" formControlName="message" cols="15" rows="5"></textarea>
                </div><br>

                <div class="submit-block">
										<button type="submit" [disabled]='!professionalRatingForm.valid' (click)="rateProfessionalService()">Submit</button>
									</div>

                <!-- <div class="report-anchor">
                  <a data-dismiss="modal">No Thanks</a>
                  <a (click)="Booking()" class="yes-anchor">Submit</a>
                  </div> -->
              </form>
              
            </div>
          </div>

        </div>
      </div>
    </div>


      <div class="agent-wrapper flex-mkt">
        <div class="agents-block col-sm-6" *ngFor="let book of bookingsList">
          <table>
            <tr>
              <td>Date:</td>
              <td><span>{{book.date}}</span></td>
            </tr>
            <tr>
              <td>Time:</td>
              <td><span>{{book.time}}</span></td>
            </tr>
            <tr>
              <td>Status:</td>
              <td><span class="ongoing-block">{{book.status}}</span></td>
            </tr>
          </table>
          <!-- <a>View Details</a> -->
        </div>
      </div>
    </div>
  </div>

</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  currentURL='';
  blogList: any;
  constructor(public httpService: HttpService,
    public router: Router,  private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.getBlogs();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
});
  }

  getBlogs() {
    this.httpService.httpRequest(`blog/getBlogs`, HttpVerb.GET  ).
      subscribe((response: any) => {
        if(response.responseCode == 200){
            this.blogList = response.responseData.blogs;
            
        }
        console.log(response);
    })
  }
  blogDetail(blog){
    console.log(blog);
    this.router.navigate(['/blog-detail', blog._id]);
  }

  getTimeofBlogs(value){
    let dateAndTime:any;
    dateAndTime = moment(value).subtract(1, 'months').format('MMMM Do YYYY, h:mm:ss a');
    return dateAndTime;

  }
  copyforShareLocation(){
    this.currentURL = window.location.href; 
    console.log( this.currentURL);
    this.copyText( this.currentURL);
  }
  copyText(val: string){
    let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastr.success("The link has been copied, you can now share it with your contacts.","",{
        timeOut: 3500,
      });
    }

}

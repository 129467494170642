import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {RazorpayComponent} from "./components/razorpay/razorpay.component";
import {ThankYouComponent} from "./components/thank-you/thank-you.component";

const routes: Routes = [
  { path: 'payment', component: RazorpayComponent },
  { path: 'thank-you', component: ThankYouComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
})
export class PaymentRoutingModule { }

<div class="dating-app">
  <div class="dating__logo"><img src="assets/images/heart.png" alt="dating app logo" /></div>
  <form class="dating__form">
    <input class="dating__inp" name="mobile" formcontrol="mobile" type="number" name="mobile"
      placeholder="Mobile Number" />
    <input class="dating__inp" name="mobile" formcontrol="mobile" type="number" name="mobile"
      placeholder="Enter Code" />
    <button class="btn btn--primary mt-15">Send Code</button>
  </form>
</div>

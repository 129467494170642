<div id="wrapper">

  <div class="common-wrapper">
    <div class="container">
      <div class="blog-single-section">
        <figure>
          <img src="{{blogData.image}}" alt="">
        </figure>
        <h3>{{blogData.title}}</h3>
        <div class="single-time">
        <ul class="single-detail">
                      <li><a><img src="assets/images/share-img.png">Share</a></li>
                  </ul>
                  <span><i class="fa fa-clock-o" aria-hidden="true"></i><strong> 20 </strong>minutes</span>
              </div>
              <p>{{blogData.description}}</p>
        </div>
    </div>
  </div>

</div>
<!-- <form class="flex-mkt profile-form" (ngSubmit)="onSubmit()" [formGroup]="profileForm">
<div id="wrapper">
  <div class="common-pad">
    <div class="update-banner">
      <img src="assets/images/profile-banner.png" alt="">
      <div class="update-banner-content">
        <div class="container">
          <figure>
            <img src="assets/images/profile-banner-image.png" alt="">
            </figure>
          <div class="update-bnnr-cntnt">
            <input type="text" placeholder="Enter Name" formControlName="name"/>
            <div class="profile-anchor-block">
              <a>jacklhhfgfgfgdsf123@gmail.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="upgrade-seciton">
    <div class="container">
      <div class="update-form">
          <div class="form-group col-sm-6">
            <label>Gender</label>
            <div class="gender-block flex-mkt">
              <div class="male-block">
                <input type="checkbox" checked="checked">
                <label>
                  <figure><img src="assets/images/gender-image.png" alt=""></figure>
                </label>
                <span>Male</span>
              </div>
              <div class="male-block">
                <input type="checkbox">
                <label>
                  <figure><img src="assets/images/gender-image.png" alt=""></figure>
                </label>
                <span>Female</span>
              </div>
              <div class="male-block">
                <input type="checkbox">
                <label>
                  <figure><img src="assets/images/gender-image.png" alt=""></figure>
                </label>
                <span>Others</span>
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label>Date of Birth</label>
            <div class="input-wrap">
              <input type="text" placeholder="date-picker">
              <img src="assets/images/date-image.png" alt="">
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label>Sexual Orientation</label>
            <div class="select-wrap">
              <select formControlName="sexualOrientation">
                <option [value]="0">Straight</option>
                <option [value]="1">Gay</option>
                <option [value]="2">Lesbo</option>
              </select>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label>Location</label>
            <input type="text" placeholder="Enter here">
          </div>
          <div class="form-group col-sm-6">
            <label>Show Me</label>
            <div class="gender-block flex-mkt">
              <div class="male-block">
                <input type="checkbox" checked="checked">
                <label>
                  <figure><img src="assets/images/gender-image.png" alt=""></figure>
                </label>
                <span>Male</span>
              </div>
              <div class="male-block">
                <input type="checkbox">
                <label>
                  <figure><img src="assets/images/gender-image.png" alt=""></figure>
                </label>
                <span>Female</span>
              </div>
              <div class="male-block">
                <input type="checkbox">
                <label>
                  <figure><img src="assets/images/gender-image.png" alt=""></figure>
                </label>
                <span>Others</span>
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label>Intrest</label>
            <div class="select-wrap">
              <select formControlName="interest">
                <option [value]="0">Straight</option>
                <option [value]="1">Gay</option>
                <option [value]="2">Lesbo</option>
              </select>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label>Maximum Distance<span>( In Km )</span></label>
            <input type="text" formControlName="distance" placeholder="Enter here">
          </div>
          <button type=submit>Continue</button>

      </div>
    </div>
  </section>

</div>
</form> -->

	<!--Page Wrapper Start-->		
	<div id="wrapper">

		<div class="common-pad">
			<div class="profile-banner">
				<img src="assets/images/profile-banner.png" alt="">
				<div class="profile-banner-content">
					<div class="container flex-mkt">
						<div>
							<figure>
								<input type="file" id="picture" (change)="onProfileChange($event)"
								placeholder="Choose Picture" style="display: none;">
								<img src='{{profileImage}}' onclick="document.getElementById('picture').click()" onerror="this.src='assets/images/profile-banner-image.png'" />
							</figure>
						</div>
						<!-- <figure>
							<img src="assets/images/profile-banner-image.png" alt="">
						</figure> -->
						<div class="profile-bnnr-cntnt">
							<h1 class="flex-mkt">{{profileName}}
								<figure style="margin-inline-end: -6px;" *ngFor="let item of [].constructor(this.avgRating);">
									<img *ngIf="(item==1||item>0.1)"src="assets/images/color-star.png">
									<img *ngIf="(item==2||item>1.1)"src="assets/images/color-star.png">
									<img *ngIf="(item==3||item>2.1)"src="assets/images/color-star.png">
									<img *ngIf="(item==4||item>3.1)"src="assets/images/color-star.png">
									<img *ngIf="(item==5||item>4.1)"src="assets/images/color-star.png">
								</figure>
							</h1>
							<div class="profile-anchor-block flex-mkt">
								<!-- <a>jacklhhfgfgfgdsf123@gmail.com</a> -->
								<!-- <a href="#" class="chng-paswd">Change Password</a> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="photo-upload-section common-wrapper">
			<div class="container">
				<div class="photo-upload-wrapper common-head">
					<h1>My Photos</h1>
					<input type="file" style="display:none;" id="myFileInput" (change)="onFileChange($event)"/>
					<a style="cursor: pointer; color: white;" onclick="document.getElementById('myFileInput').click()">+ Add Photo</a>
				</div>
				<div class="photo-gallery flex-mkt">
					<div class="col-sm-3" *ngFor="let photos of userPhotos">
						<figure><img src="{{photos}}" alt=""></figure>
					</div>
					<!-- <div class="col-sm-3">
						<figure><img src="assets/images/profile-gallery-image.png" alt=""></figure>
					</div>
					<div class="col-sm-3">
						<figure><img src="assets/images/profile-gallery-image.png" alt=""></figure>
					</div>
					<div class="col-sm-3">
						<figure><img src="assets/images/profile-gallery-image.png" alt=""></figure>
					</div>
          <div class="col-sm-3">
						<figure><img src="assets/images/profile-gallery-image.png" alt=""></figure>
					</div> -->
				</div>
				<div class="personal-block chnge-head">
					<h1>Personal Information</h1>
					<div class="personal-form">
						<form class="flex-mkt profile-form" [formGroup]="profileForm">
							<div class="form-group col-sm-6">
								<label>Name</label>
								<input type="text" formControlName="name"  placeholder="Enter Name">
							</div>
							<div class="form-group col-sm-6">
								<label>About me</label>
								<textarea name="comment" formControlName="about" form="usrform" placeholder="Enter text here..."></textarea>
							</div>
							<div class="form-group col-sm-6">
								<label>Phone No</label>
								<input type="tel" class="pointer-none" value="{{userData.phone}}" placeholder="(480) 555-0103" >
							</div>
							<div class="form-group col-sm-6">
								<label>Email Id.</label>
								<input type="Email" class="pointer-none" value="{{userData.email}}" placeholder="Kapli@gmail.com">
							</div>
							<div class="form-group col-sm-6">
								<label>Date of Birth</label>
								<input [owlDateTime]="dt1" [max]="today" [owlDateTimeTrigger]="dt1" formControlName="dob" placeholder="Date of Birth">
										<owl-date-time #dt1 pickerType="calendar"></owl-date-time>
								<!-- <label>Date of Birth</label>
								<ngx-datepicker formControlName="dob" placeholder="Date of Birth"></ngx-datepicker> -->
								<!-- <input type="text" placeholder="9/18/16"> -->
							</div>
							<!-- gender -->
							<div class="form-group col-sm-6">
								<label>I Am</label>
								<div class="gender-block flex-mkt single-selection">
									<div class="male-block">
										<input type="radio" [value]="0" formControlName="gender" id="gender-one">
										<label for="gender-one">
											<figure><img src="assets/images/mail.png" alt=""></figure>
										</label>
										<span>Male</span>
									</div>
									<div class="male-block">
										<input type="radio" [value]="1" formControlName="gender" id="gender-two">
										<label for="gender-two">
											<figure><img src="assets/images/gender-image.png" alt=""></figure>
										</label>
										<span>Female</span>
									</div>
									<div class="male-block">
										<input type="radio" [value]="2" formControlName="gender" id="gender-three">
										<label for="gender-three">
											<figure><img src="assets/images/maskgroup.png" alt=""></figure>
										</label>
										<span>Others</span>
									</div>
								</div>
							</div>
							<!-- sexual oriantation -->
							<div class="form-group col-sm-6">
								<label>Sexual Orientation</label>
								<div class="select-wrap">
									<select  formControlName="sexualOrientation">
										<option value="0">Straight</option>
										<option value="1">Gay</option>
										<option value="2">Bisexual</option>
										<option value="3">Queer</option>
										<option value="4">Asexual</option>
									</select>
								</div>
							</div>
							<!-- gender preference -->

							<div class="form-group col-sm-6">
								<label>Show me</label>
								<div class="gender-block flex-mkt single-selection">
									<div class="male-block">
										<input type="radio" id="show-one" [value]="0" formControlName="genderPreference">
										<label for="show-one">
											<figure><img src="assets/images/mail.png" alt=""></figure>
										</label>
										<span>Male</span>
									</div>
									<div class="male-block">
										<input type="radio" id="show-two"  [value]="1" formControlName="genderPreference">
										<label for="show-two">
											<figure><img src="assets/images/gender-image.png" alt=""></figure>
										</label>
										<span>Female</span>
									</div>
									<div class="male-block">
										<input type="radio" id="show-three" [value]="2" formControlName="genderPreference">
										<label for="show-three">
											<figure><img src="assets/images/maskgroup.png" alt=""></figure>
										</label>
										<span>Other</span>
									</div>
								</div>
							</div>

							<!-- interest -->
							<div class="form-group col-sm-6">
								<label>Intrest</label>
								<div class="gender-block flex-mkt multiselect multiselect-huk" >
									<div class="male-block" *ngFor="let intst of interest; let i = index">
										<input
										  type="checkbox"
										  value="{{ intst }}"
										  [(ngModel)]="intst.status"
										  [ngModelOptions]="{ standalone: true }"
										/>
										<!-- <input type="checkbox" [value]="intst" (change)="onCheckChange($event)"> -->
										<label>{{intst.name}}</label>
									</div>
								</div>
							</div>
 							<!-- hair color -->
							<div class="form-group col-sm-6">
								<label>Hair color</label>
								<div class="gender-block flex-mkt single-selection">
									<div class="male-block">
										<input type="radio" id="hair-three" [value]="0" formControlName="hairColor">
										<label for="hair-three">
											<figure><img src="assets/images/maskgroup.png" alt=""></figure>
										</label>
										<span>Black</span>
									</div>
									<div class="male-block">
										<input type="radio" id="hair-one" [value]="2" formControlName="hairColor">
										<label for="hair-one">
											<figure><img src="assets/images/mail.png" alt=""></figure>
										</label>
										<span>Red</span>
									</div>
									<div class="male-block">
										<input type="radio" id="hair-two"  [value]="1" formControlName="hairColor">
										<label for="hair-two">
											<figure><img src="assets/images/gender-image.png" alt=""></figure>
										</label>
										<span>Brown</span>
									</div>
								
								</div>
							</div>
							<div class="form-group col-sm-6">
								<label>Maximum Distance<span>( In Km )</span></label>
								<input type="text" formControlName="distance" placeholder="2 Km">
							</div>
                             <!-- hair Preference -->

							<div class="form-group col-sm-6">
								<label>Hair Perference</label>
								<div class="gender-block flex-mkt single-selection">
									<div class="male-block">
										<input type="radio" [value]="0" formControlName="hairPreference" id="other-pref" >
										<label for="other-pref">
											<figure><img src="assets/images/maskgroup.png" alt=""></figure>
										</label>
										<span>Black</span>
									</div>
									<div class="male-block">
										<input type="radio" [value]="2" formControlName="hairPreference" id="hair-pref" checked="checked">
										<label for="hair-pref">
											<figure form><img src="assets/images/mail.png" alt=""></figure>
										</label>
										<span>Red</span>
									</div>
									<div class="male-block">
										<input type="radio" [value]="1" formControlName="hairPreference" id="female-pref">
										<label for="female-pref">
											<figure><img src="assets/images/gender-image.png" alt=""></figure>
										</label>
										<span>Brown</span>
									</div>
									
								</div>
							</div>

							<div class="form-group col-sm-6">
								<label>My height (in cm)</label>
								<div class="select-wrap">
									<select formControlName="height">
										<option value="155 to 160">155 to 160</option>
										<option value="160 to 165">160 to 165</option>
										<option value="165 to 170">165 to 170</option>
										<option value="170 to 175">170 to 175</option>
										<option value="175 to 180">175 to 180</option>
										<option value="180 or above">180 or above</option>
									</select>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<label>My weight (in kg)</label>
								<div class="select-wrap">
									<select formControlName="weight">
										<option value="40 to 45">40 to 45</option>
										<option value="45 to 50">45 to 50</option>
										<option value="50 to 55">50 to 55</option>
										<option value="55 to 60">55 to 60</option>
										<option value="60 to 65">60 to 65</option>
										<option value="65 to 70">65 to 70</option>
										<option value="70 to 75">70 to 75</option>
										<option value="75 to 80">75 to 80</option>
										<option value="80 to 85">80 to 85</option>
									</select>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<label>Height Preference (in cm)</label>
								<div class="select-wrap">
									<select formControlName="heightPreference">
										<option value="155 to 160">155 to 160</option>
										<option value="160 to 165">160 to 165</option>
										<option value="165 to 170">165 to 170</option>
										<option value="170 to 175">170 to 175</option>
										<option value="175 to 180">175 to 180</option>
										<option value="180 or above">180 or above</option>
									</select>
								</div>
							</div>
							<div class="form-group col-sm-6">
								<label>Weight preference (in kg)</label>
								<div class="select-wrap">
									<select formControlName="weightPreference">
										<option value="40 to 45">40 to 45</option>
										<option value="45 to 50">45 to 50</option>
										<option value="50 to 55">50 to 55</option>
										<option value="55 to 60">55 to 60</option>
										<option value="60 to 65">60 to 65</option>
										<option value="65 to 70">65 to 70</option>
										<option value="70 to 75">70 to 75</option>
										<option value="75 to 80">75 to 80</option>
										<option value="80 to 85">80 to 85</option>
									</select>
								</div>
							</div>

							<!-- <div class="form-group col-sm-6">
								<label>Location</label>
								<input type="text" placeholder="Noida, Uttar Pradesh">
							</div> -->
							<div class="form-button col-sm-12 margin-zero">
								<a>Detect Location</a>
								<a>Connect Instagram</a>
							</div>
							<div class="form-button col-sm-12">
								<a (click)="onSubmit()" class="btn btn-info">Save</a>
							</div>
						</form>
					</div>
					<h1>Change Password</h1>
					<div class="change-form">
						<form class="profile-form" [formGroup]="changeForm">
							<div class="form-group col-sm-12">
								<label>Old Password</label>
								<input type="text" formControlName="oldPassword" placeholder="Enter Old Password">
							</div>
							<div class="form-group col-sm-12">
								<label>New Password</label>
								<input type="text" formControlName="newPassword" placeholder="Enter New Password">
							</div>
							<button type="submit" [disabled]="!changeForm.valid" (click)="changePassword()">
								Change Password
							</button>
						</form>
					</div>
				</div>
			</div>
		</div>

	</div>
	<!--Page Wrapper End-->

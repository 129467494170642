import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpVerb } from '../core/services/enum/const.enum';
import { HttpService } from '../core/services/http/http.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  bookingData: any;
  agentData: any;
  subsription: any;
  constructor(
    public httpService: HttpService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    this.bookingData = JSON.parse(localStorage.getItem('bookingData'));
    this.agentData = JSON.parse(localStorage.getItem('agentData'));
    this.subsription = localStorage.getItem('subscription');
    if(this.bookingData){
      this.httpService.httpRequest(`professionalBooking/bookProfessional`, HttpVerb.POST, this.bookingData).
      subscribe((response: any) => {
        console.log(response);
        if(response.responseCode ==200){
          this.toastr.success("Booking Confirmed");
          localStorage.removeItem('bookingData');
        }
      })
    }
    if(this.agentData){
      this.httpService.httpRequest(`agentBooking/bookAgent`, HttpVerb.POST, this.agentData).
      subscribe((response: any) => {
        // console.log(response);
        if(response.responseCode ==200){
          this.toastr.success("Agent Confirmed");
          localStorage.removeItem('agentData');
        }
      })
    }
    if(this.subsription){
      this.toastr.success("Subscription Purchsed");
      localStorage.removeItem('subscription');
    }

  }

}

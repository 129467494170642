// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl:'http://3.131.110.213:4000/'
  // baseUrl:'http://royal-flings.ch:5001/api/v1/'
  baseUrl:'https://api.royal-flings.ch/api/v1/',
  firebase: {
    apiKey: "AIzaSyCabHiaXeYNoZzCVN9AztLkGft2jhS5XHo",
    authDomain: "dating-web-16ecf.firebaseapp.com",
    databaseURL: "https://dating-web-16ecf-default-rtdb.firebaseio.com",
    projectId: "dating-web-16ecf",
    storageBucket: "dating-web-16ecf.appspot.com",
    messagingSenderId: "826601638535",
    appId: "1:826601638535:web:08df2c7b5a9a5ede0a8622",
  },
  API_KEY: "AIzaSyAbnYgXh72JS464-h4QCmFQVeEsonWTrt8"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

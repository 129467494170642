import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {FooterComponent} from "./footer/footer.component";
import {HeaderComponent} from "./header/header.component";
import {ModalComponent} from "../modal/modal.component";

const routes:Routes = [
  {path:'footer', component:FooterComponent},
  {path:'header', component:HeaderComponent},
  {path:'modal', component:ModalComponent}
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule { }

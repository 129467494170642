import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpVerb} from "../../../../core/services/enum/const.enum";
import {HttpService} from "../../../../core/services/http/http.service";
import {Register} from "../../models/login";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginComponent} from "../login/login.component";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  private registerPayload: Register;
  registerForm:FormGroup;
  showOTP: boolean = false;
  private  registerResponse: any;
@ViewChild(LoginComponent) loginComponent:LoginComponent;
@ViewChild('closeModal') closeModal: ElementRef;
  constructor(private fb:FormBuilder,
    private httpService:HttpService, 
    private router: Router,
    private toastr: ToastrService,
    private socialAuthService: SocialAuthService) {}
registerUser=()=> {
    this.registerForm=this.fb.group({
      deviceID: ['qwerty',''],
      deviceToken: ['qwerty',''],
      deviceTypeID: ['qwerty',''],
      name:['', Validators.required],
      email:['', Validators.required],
      countryCode:['', Validators.required],
      phone:['', Validators.required],
      password:['', Validators.required],
      otp: ['','']
    })}
  ngOnInit(): void {this.registerUser();
   
    this.registerForm.reset();
  }

  onSubmit=() =>{
    this.registerResponse = this.httpService.httpRequest(`user/signup`,HttpVerb.POST,this.registerForm.value)
      .subscribe((response:any)=>{
      console.log(response)
      if(response.responseCode == 200){
        this.toastr.success(response.responseMessage, 'OTP Sent!');
        this.showOTP = true;
      }
      },error => {console.log(error)});
  }

  loginForm(){
    this.router.navigate(['/home']);
  }
  onVerify(){
    if(!this.registerForm.value.otp){
      this.toastr.error("Enter OTP to Verify");
      return;
    }
    let obj = {
      phone: this.registerForm.value.phone,
      countryCode: this.registerForm.value.countryCode,
      otp: this.registerForm.value.otp
    }
    this.registerResponse = this.httpService.httpRequest(`user/verifyotpForSignUp`,HttpVerb.POST,obj)
      .subscribe((response:any)=>{
      if(response.responseCode == 200){
        this.toastr.success("Account Created, Please login", 'Account Verified!');
        this.closeModal.nativeElement.click();
      }else{
        this.toastr.error(response.responseMessage,"",)
      }
      },error => {console.log(error)});
  }
  ngOnDestroy(): void {this.registerResponse.unsubscribe()}

  onGoogleSignIn(){
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (user)=> {
        this.httpService.googleSignIn(user.idToken).subscribe(
          (res)=> {
            console.log(res);
          },
          (err)=> {
            console.log(err);
          }
        )
      }
    )
  }

  resetForm(){
    this.registerForm.reset();
  }
}

<div class="dating-app" *ngIf="!reset">
 <i class="fa fa-arrow-left" aria-hidden="true" style="margin-left: 90%; cursor: pointer;" (click)="backtoHome()">&nbsp;Back</i>
  <div class="dating__logo"><img src="assets/images/logo.png" alt="dating app logo" /></div>
  <form class="dating__form" [formGroup]="forgotForm">

    <div class="form-group">
      <select name="countryCode" id="countryCode" formControlName="countryCode" class="dropDown">
          <option value="" disabled>Select Country Code</option>
          <option value="+91" selected>+91 (India)</option>
          <option value="+81">+81</option>
          <option value="+85">+85</option>
          <option value="+92">+92</option>
      </select>
  </div>
  
  <div class="form-group">
    <!-- <input type="tel" name="lmob" class="form-control" formControlName="phone" placeholder="Mobile" required> -->
    <input class="form-control" name="mobile" formControlName="phone" type="text" name="mobile"
      placeholder="Mobile Number" />
  </div>
  <div class="form-group">
    <input *ngIf="optField" type="text" formControlName="otp" class="form-control" name="otp"
      placeholder="Enter OTP" required="" />
    <!-- <input class="form-control" name="mobile" formControlName="phone" type="text" name="mobile"
      placeholder="Mobile Number" /> -->
  </div>
    <!-- <input class="dating__inp" name="mobile" formControlName="phone" type="text" name="mobile"
      placeholder="Mobile Number" /> -->
      <!-- <input *ngIf="optField" type="text" formControlName="otp" class="dating__inp" name="otp"
      placeholder="Enter OTP" required="" /> -->
    <button class="btn btn--primary mt-15 " (click)="sentOtp()" *ngIf="!optField">Send Code</button>
    <button class="btn btn--primary mt-15" [disabled]="forgotForm.invalid" (click)="onSubmit()" *ngIf="optField">Verify</button>
  </form>
</div>


<div class="dating-app" *ngIf="reset">
  <div class="dating__logo"><img src="assets/images/logo.png" alt="dating app logo" /></div>
  <form class="dating__form" [formGroup]="resetForm">
    <input class="dating__inp" name="mobile" formControlName="newPassword" type="password" name="mobile"
      placeholder="New Password" />
    <input class="dating__inp" name="mobile" formControlName="confPassword" type="text" name="mobile"
      placeholder="Confirm Password" />
    <button [disabled]="resetForm.invalid" (click)="resetPassword()" class="btn btn--primary mt-15">Reset</button>
  </form>
</div>

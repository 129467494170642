import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tnc',
  template: `
    <div class="common-wrapper">
      <div class="container">
        <div class="legal-section">
          <h1>Terms And Condition</h1>
          <p>Risus, enim, ipsum tellus amet, sit. Urna at sed consectetur at vulputate a, tempus. Dictumst viverra diam pharetra at id vel malesuada risus. Elementum bibendum semper mi porttitor ornare rhoncus, elementum. Massa ac fusce vestibulum mauris purus aliquet vitae amet. Bibendum nec at imperdiet auctor nulla. Scelerisque odio fringilla morbi massa imperdiet diam purus viverra diam. Justo congue eget lacinia nulla in ac amet. Purus in mauris nulla cursus commodo euismod blandit. Fringilla fusce adipiscing elit mattis in.</p>
          <p>Vitae amet, quam quam suspendisse lectus. Imperdiet in quam sed mauris vitae, ut lectus. Lectus sed erat etiam viverra velit volutpat. Aliquet phasellus fusce id nisl mattis enim. Faucibus est lorem sit vulputate. Non dolor lacus blandit arcu. Urna sed elit purus nam accumsan. Rhoncus, vitae, amet praesent egestas ac viverra semper. Tristique quam lectus scelerisque id nisl, mauris auctor dolor tristique. Convallis nibh dolor eu eu quam bibendum et vestibulum. At convallis arcu ac ultricies mi ac quam. Non gravida vitae pellentesque scelerisque eget vestibulum.</p>
          <p>Rhoncus ipsum orci accumsan amet amet. Maecenas eget sed quisque massa nunc et lectus. Viverra hac porttitor vitae congue ullamcorper. Aliquam quam tellus ultrices diam dictum enim porta eget. Adipiscing nam egestas elit diam lacus in feugiat nulla ultrices. Sit elementum posuere quam eget elit. Sodales vel donec hac integer adipiscing lobortis id amet. Lectus in mi in ullamcorper sed felis ut ultrices. Iaculis vestibulum venenatis est, ultrices quisque velit lobortis enim. Enim dignissim dolor id urna sapien, cras leo dictumst. Cursus egestas tellus volutpat dui eu. Faucibus volutpat aenean arcu dignissim proin quisque porttitor augue gravida. Blandit enim aliquet lacus faucibus et donec sagittis ut. Nisl morbi iaculis risus amet.</p>
          <p>Sem egestas dolor consequat lobortis leo faucibus eget. Mi nisl amet urna pretium etiam iaculis. Neque lectus quis varius feugiat. Nulla amet lobortis eget phasellus condimentum in tempor elit faucibus. Quisque integer urna, metus quisque id euismod bibendum. Morbi pharetra accumsan in interdum ornare tristique. Nisi, vel ut felis diam vel scelerisque maecenas enim. Sed commodo risus lacus venenatis duis sed sit pellentesque quisque. Leo pulvinar placerat sed et amet. Amet, eu sit aenean pretium, turpis diam laoreet aliquet sit. Eget eu eu,</p>
          <p>vestibulum adipiscing. Viverra eu, sapien mi commodo nibh volutpat, quisque at. Nunc tellus sed pellentesque commodo diam volutpat luctus consequat. Pellentesque posuere id adipiscing laoreet vestibulum lacus, ipsum, enim eu.</p>
        </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class TncComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
});
  }

}

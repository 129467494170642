import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import {BlogComponent} from "./components/blog/blog.component";

const routes: Routes = [
  {path:'', component:BlogComponent},
  { path: 'blog-detail/:blog_id', component: BlogDetailsComponent },
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlogRoutingModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentFailComponent } from './payment-fail/payment-fail.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'layout',
    loadChildren: () =>
      import('./shared/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./modules/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'swipe',
    loadChildren: () =>
      import('./modules/swipe/swipe.module').then((m) => m.SwipeModule),
  },
  {
    path: 'dating',
    loadChildren: () =>
      import('./modules/flings/flings.module').then((m) => m.FlingsModule),
  },
  {
    path: 'success', component: PaymentSuccessComponent
  },
  {
    path: 'failure', component: PaymentFailComponent
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

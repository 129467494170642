<!--<div class="wrapper">-->
<!--  &lt;!&ndash; <app-header></app-header> &ndash;&gt;-->
<!--  <div class="photo">-->
<!--    <img src="assets/images/mask-group.jpg" alt="" />-->
<!--  </div>-->
<!--  <div class="pulse"> </div>-->
<!--  <div class="photo1"><img src="assets/images/index.jpeg" alt="" /></div>-->
<!--</div>-->
<!--<p> latitude: <span id="lat"> </span>&deg;<br /></p>-->
<!--<p> longitude: <span id="long"> </span>&deg;<br /></p>-->
<!-- <h1>{{ title }}</h1> -->

<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map: -->
<!-- <agm-map [latitude]="lat" [longitude]="lng">
  <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
</agm-map> -->

<div id="wrapper">

  <div class="video-home common-pad">
    <div class="container-fluid nopadding">
      <img src="assets/images/mask-group.jpg" alt="" />
    </div>
  </div><!-- video-home -->

  <div class="profile-wrapper">
    <div class="container">
      <h2 class="common-title">Near By</h2>
      <div class="instagram-wrapper flex-mkt">
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online offline"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>
        <div class="profile-block instagram-block">
          <div class="user-img">
            <a href="#">
              <div class="profile-img">
                <img src="assets/images/maskgroup.png" alt="" />
              </div>
              <div class="user-identification">
                <h2>Garima <span>21y</span></h2>
                <address>Noida, India</address>
                <span class="online"></span>
              </div>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

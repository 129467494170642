import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  walletPoints: any;
  userRating
  constructor(
    public httpService: HttpService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.httpService.httpRequest(`user/myWallet`, HttpVerb.GET).
    subscribe((response: any) => {
      console.log(response);
      if (response.responseCode == 200) {
        this.walletPoints = response.responseData.walletPoints;
        // this.photoShoots = response.responseData.pastShoots;
        // console.log(this.professionalRating);
        this.userRating = response.responseData.rating;
        let temp = [];
        let rateTemp = []
        this.userRating.forEach(element => {
          for (let index = 0; index < element.rating; index++) {
            rateTemp.push(index);
        }
          element.rating = rateTemp;
          rateTemp = [];
          temp.push(element);
        });
        this.userRating = temp;
      }
    })
  }

  redeem(){
    this.httpService.httpRequest(`user/redeemWallet`, HttpVerb.GET).
    subscribe((response: any) => {
      // console.log(response);
      if (response.responseCode == 200) {
        this.toastr.success("Points Redeem Successfully");
      }
      if(response.responseCode == 403){
        this.toastr.warning(response.responseMessage);
      }
    })
  }
}


<div id="wrapper">
  <div class="common-wrapper">
    <div class="container">
      <div class="wallet-section chnge-head">
        <h1>Wallet</h1>
        <div class="earn-block">{{walletPoints}} <span>Points Earned</span>
          <a type="button" style="float: right;" (click)="redeem()" class="btn btn-primary">Redeem Points</a>
        </div>
      </div>
    </div>
  </div>

  <!-- <section class="rating-section agent-after-block">
    <div class="container">
      <h1>Rating By Matches</h1>
      <div class="rating-block flex-mkt">
        <figure>
          <img src="assets/images/comment-image.png" alt="">
        </figure>
        <div class="rating-details">
          <h2>Garima</h2>
          <figure><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"></figure>
          <p>Sit tellus sit sem venenatis etiam vel donec nisi. Cursus eget id id felis laoreet. Scelerisque non pellentesque arcu rhoncus.</p>
        </div>
      </div>
      <div class="rating-block flex-mkt">
        <figure>
          <img src="assets/images/comment-image.png" alt="">
        </figure>
        <div class="rating-details">
          <h2>Garima</h2>
          <figure><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"><img src="assets/images/color-star.png"></figure>
          <p>Sit tellus sit sem venenatis etiam vel donec nisi. Cursus eget id id felis laoreet. Scelerisque non pellentesque arcu rhoncus.</p>
        </div>
      </div>
    </div>
  </section> -->

  <section class="rating-section agent-after-block">
		<div class="container">
			<h1>Rating By Matches</h1>
			<div class="rating-block flex-mkt" style="cursor: pointer;" [routerLink]="['/swipe-detail', rate.userId._id]" *ngFor="let rate of userRating">
				<figure>
					<img src="{{rate.userId.profileImage}}" alt="">
				</figure>
				<div class="rating-details">
					<h2>{{rate.userId.name}}</h2>
					<!-- <figure>
						<ngx-star-rating value=5 [id]="'rating'"></ngx-star-rating>
					</figure> -->
					<!-- <app-star-rating [rating]="2" [isEditable] = "true"></app-star-rating>  -->
					<div class="row">
						<figure style="margin-inline-end: -6px;" *ngFor="let star of rate.rating"> 
							<img src="assets/images/color-star.png">
						</figure>
					</div>
					<p>{{rate.message}}</p>
				</div>
			</div>
		</div>
	</section>
</div>

<div id="wrapper">



  <!--    <div class="common-wrapper profile-wrapper">-->
  <!--      <div class="container">-->
  <!--        <div class="swipe-wrap center">-->
  <!--          <div class="swip-block">-->
  <!--            <a href="swipe-detail.php">-->
  <!--              <figure><img src="assets/images/mask-group.jpg" alt=""></figure>-->
  <!--              <div class="swip-content">-->
  <!--                <h1>Dollie Lynch</h1>-->
  <!--                <div class="loc-block">-->
  <!--                  Noida, India-->
  <!--                </div>-->
  <!--                <div class="choice-block flex-mkt">-->
  <!--                  <a href="#"><img src="assets/images/swipe-more.png" alt=""></a>-->
  <!--                  <a href="#"><img src="assets/images/swipe-like.png" alt=""></a>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </a>-->
  <!--          </div>-->
  <!--          <div class="swip-block">-->
  <!--            <a href="swipe-detail.php">-->
  <!--              <figure><img src="assets/images/mask-group.jpg" alt=""></figure>-->
  <!--              <div class="swip-content">-->
  <!--                <h1>Dollie Lynch</h1>-->
  <!--                <div class="loc-block">-->
  <!--                  Noida, India-->
  <!--                </div>-->
  <!--                <div class="choice-block flex-mkt">-->
  <!--                  <a href="#"><img src="assets/images/swipe-more.png" alt=""></a>-->
  <!--                  <a href="#"><img src="assets/images/swipe-like.png" alt=""></a>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </a>-->
  <!--          </div>-->
  <!--          <div class="swip-block">-->
  <!--            <a href="swipe-detail.php">-->
  <!--              <figure><img src="assets/images/mask-group.jpg" alt=""></figure>-->
  <!--              <div class="swip-content">-->
  <!--                <h1>Dollie Lynch</h1>-->
  <!--                <div class="loc-block">-->
  <!--                  Noida, India-->
  <!--                </div>-->
  <!--                <div class="choice-block flex-mkt">-->
  <!--                  <a href="#"><img src="assets/images/swipe-more.png" alt=""></a>-->
  <!--                  <a href="#"><img src="assets/images/swipe-like.png" alt=""></a>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </a>-->
  <!--          </div>-->
  <!--          <div class="swip-block">-->
  <!--            <a href="swipe-detail.php">-->
  <!--              <figure><img src="assets/images/mask-group.jpg" alt=""></figure>-->
  <!--              <div class="swip-content">-->
  <!--                <h1>Dollie Lynch</h1>-->
  <!--                <div class="loc-block">-->
  <!--                  Noida, India-->
  <!--                </div>-->
  <!--                <div class="choice-block flex-mkt">-->
  <!--                  <a href="#"><img src="assets/images/swipe-more.png" alt=""></a>-->
  <!--                  <a href="#"><img src="assets/images/swipe-like.png" alt=""></a>-->
  <!--                </div>-->
  <!--              </div>-->
  <!--            </a>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->


  <!-- <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide"><img src="assets/images/duo-tone-lighting-portrait-latina-260nw-1430899970.webp"></div>
      <div class="swiper-slide"><img src="assets/images/index.jpeg"></div>
      <div class="swiper-slide"><img src="assets/images/index1.jpeg"></div>
      <div class="swiper-slide"><img src="assets/images/images1.jpeg"></div>
      <div class="swiper-slide"><img src="assets/images/images1.jpeg"></div>
      <div class="swiper-slide"><img src="assets/images/images1.jpeg"></div>
      <div class="swiper-slide"><img src="assets/images/images1.jpeg"></div>
      <div class="swiper-slide"><img src="assets/images/images1.jpeg"></div>
      <div class="swiper-slide"><img src="assets/images/images1.jpeg"></div>
    </div>
  </div> -->





  <!-- Button trigger modal -->

  <!-- Modal -->



  <!--  <div class="swipes">-->
  <!--    <div class="container">-->
  <!--      <div class="card" *ngFor="let swipe of swipes; index as i">-->
  <!--        <div class="imgBx">-->
  <!--          <img src="assets/images/images1.jpeg">-->
  <!--        </div>-->
  <!--        <div class="content">-->
  <!--          <h2>tru </h2>-->
  <!--          <p>Available</p>-->
  <!--          <span class="online"></span>-->
  <!--        </div>-->
  <!--      </div>-->

  <!--    </div>-->

  <!--  </div>-->

  <!-- <div class="common-wrapper profile-wrapper">
    <div class="container">
      <div class="swipe-wrap center">
        <div class="swip-block">
          <a [routerLink]="['/swipe-detail', 3]">
            <figure><img src="assets/images/mask-group.jpg" alt=""></figure>
            <div class="swip-content">
              <h1>Dollie Lynch</h1>
              <div class="loc-block">
                Noida, India
              </div>
              <div class="choice-block flex-mkt">
                <a ><img src="assets/images/swipe-more.png" alt=""></a>
                <a ><img src="assets/images/swipe-like.png" alt=""></a>
              </div>
            </div>
          </a>
        </div>
        <div class="swip-block">
          <a [routerLink]="['/swipe-detail', 3]">
            <figure><img src="assets/images/mask-group.jpg" alt=""></figure>
            <div class="swip-content">
              <h1>Dollie Lynch</h1>
              <div class="loc-block">
                Noida, India
              </div>
              <div class="choice-block flex-mkt">
                <a ><img src="assets/images/swipe-more.png" alt=""></a>
                <a ><img src="assets/images/swipe-like.png" alt=""></a>
              </div>
            </div>
          </a>
        </div>
        <div class="swip-block">
          <a [routerLink]="['/swipe-detail', 3]">
            <figure><img src="assets/images/mask-group.jpg" alt=""></figure>
            <div class="swip-content">
              <h1>Dollie Lynch</h1>
              <div class="loc-block">
                Noida, India
              </div>
              <div class="choice-block flex-mkt">
                <a ><img src="assets/images/swipe-more.png" alt=""></a>
                <a ><img src="assets/images/swipe-like.png" alt=""></a>
              </div>
            </div>
          </a>
        </div>
        <div class="swip-block">
          <a [routerLink]="['/swipe-detail', 3]">
            <figure><img src="assets/images/mask-group.jpg" alt=""></figure>
            <div class="swip-content">
              <h1>Dollie Lynch</h1>
              <div class="loc-block">
                Noida, India
              </div>
              <div class="choice-block flex-mkt">
                <a ><img src="assets/images/swipe-more.png" alt=""></a>
                <a ><img src="assets/images/swipe-like.png" alt=""></a>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <swiper [slidesPerView]="3" [spaceBetween]="50" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()"
    [navigation]="true" [pagination]="{ clickable: true }">
    <ng-template swiperSlide>Slide 1</ng-template>
    <ng-template swiperSlide>Slide 2</ng-template>
    <ng-template swiperSlide>Slide 3</ng-template>
    <ng-template swiperSlide>Slide 4</ng-template>
    <ng-template swiperSlide>Slide 5</ng-template>
    <ng-template swiperSlide>Slide 6</ng-template>
  </swiper> -->
  <swiper [slidesPerView]="3"   [navigation]="true" *ngIf="ProfileList?.length>0"
  [pagination]="{ clickable: true }" [loop]="true"
  [autoplay]="true"
  [scrollbar]="{ draggable: true }"[spaceBetween]="50" [allowSlidePrev]="true" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()">
    <ng-container *ngFor="let slide of ProfileList; index as i">
      <ng-template swiperSlide>
        <div class="swip-block">
          <a >
          <div>
            <figure><img [routerLink]="['/swipe-detail', slide._id]" style="height: 330px; width: 270px;" src="{{slide.profileImage}}" onerror="this.src='assets/images/mask-group.jpg';" alt=""></figure>
          </div>
            <div class="swip-content">
              <h1>{{slide.name}}</h1>
              <div class="loc-block">
                Distance {{slide.distance |number}} KM
              </div>
              <div class="choice-block flex-mkt">
                <button (click)="likeProfile(slide, 2)" style="border: hidden; background-color: white;"><img src="assets/images/swipe-more.png" alt=""></button>
                <button (click)="likeProfile(slide, 1)" style="border: hidden; background-color: white;"><img src="assets/images/swipe-like.png" alt=""></button>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
      
    </ng-container>
  </swiper>
  <p style="margin-left: 2%;" class="notfoundMsg" *ngIf="ProfileList?.length==0">Sorry,Any Profile is not found in this time.</p>

</div>
import { Component, OnInit ,AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';
import Swiper from 'swiper';
declare var $: any;
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
  EffectCoverflow,
} from 'swiper/core';
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Mousewheel,
  EffectCoverflow,
]);
@Component({
  selector: 'app-swipe',
  templateUrl: './swipe.component.html',
  styleUrls: ['./swipe.component.css'],
})
export class SwipeComponent implements OnInit,AfterViewInit {
  consts: any =
    'assets/images/duo-tone-lighting-portrait-latina-260nw-1430899970.webp';
  swiper: any;
  constructor(
    private httpService: HttpService,
    private route: Router,
    private toastr: ToastrService
  ) { }
  swipes = [1, 2, 3, 4];
  profiles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; // change elements with real data
  ProfileList: any;

  ngOnInit(): void {
    this.getUserList();
    this.swiperObject();
    
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
});
  }

  ngAfterViewInit(): void {
   
  }
  onSwiper(swiper) {
    console.log(swiper);
  }
  onSlideChange() {
    // this.getUserList();
    console.log('slide change');
  }

  swiperObject = () => {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 5,
      spaceBetween: 50,
      grabCursor: true,
      allowSlidePrev: false,
    });
  };

  getUserList() {
    this.httpService.httpRequest(`user/profileListing`, HttpVerb.GET).
      subscribe((response: any) => {
        console.log(response);
        this.ProfileList = response.responseData;
        console.log(this.ProfileList.length)
      })
  }

  likeProfile(user, likeType) {
    // console.log(user, likeType);
    let obj = {
      users: user._id,
      likeType: likeType
    }
    // console.log(obj);
    this.httpService.httpRequest(`user/likeProfile`, HttpVerb.POST, obj).
      subscribe((response: any) => {
        console.log(response);
        if (response.responseCode == 200) {

          if (likeType == 2) {
            this.toastr.success("Profile Super Liked");
          } else {
            this.toastr.success("Profile Liked");
          }
          this.getUserList();
        }else{
          this.toastr.warning(response.responseMessage);
        }
      })
    // this.swiper.slideNext();
    this.swiper.navigation.nextEl;
  }
}


    // var swiper = new Swiper('.swiper-container', {
    //   effect: 'coverflow',
    //   grabCursor: true,
    //   centeredSlides: true,
    //   slidesPerView: 'auto',
    //   coverflowEffect: {
    //     rotate: 20,
    //     stretch: 0,
    //     depth: 200,
    //     modifier: 1,
    //     slideShadows: true,
    //   },
    //   loop: true,
    //   autoplay: {
    //     delay: 1000,
    //     disableOnInteraction: false,
    //   },
    //   pagination: {
    //     el: '.swiper-pagination',
    //   },
    // });
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  blocks = [{
    src:'assets/images/Mask Group_9.png'
  },{
    src:'assets/images/Mask Group_10.png'
  },{
    src:'assets/images/Mask Group_11.png'
  },{
    src:'assets/images/Mask Group_12.png'
  },{
    src:'assets/images/Mask Group_13.png'
  },{
    src:'assets/images/Mask Group_14.png'
  },{
    src:'assets/images/Mask Group_15.png'
  },{
    src:'assets/images/Mask Group_16.png'
  },]
  blocks2 = [{
    src:'assets/images/Mask Group_17.png'
  },{
    src:'assets/images/Mask Group_18.png'
  },{
    src:'assets/images/Mask Group_19.png'
  },{
    src:'assets/images/Mask Group_20.png'
  }]
  constructor() { }

  ngOnInit(): void {
  }

}

<div id="wrapper">

  <div class="common-wrapper">
    <div class="container">
      <div class="common-head">
        <h2>Blog</h2>
      </div>
      <div class="blog-single-section" *ngFor="let blog of blogList">
        <figure>
          <img src="{{blog.image}}" alt="">
        </figure>
        <h3 (click)="blogDetail(blog)" style="cursor: pointer">{{ blog.title }}</h3>
        <p>{{ blog.description | slice:0:50}} {{ blog.description.length > 50? '...':''}}</p>
        <!-- <p>Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole single-origin coffee church-key roof party.</p>
        <p>Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole single-origin coffee church-key roof party.Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole single-origin coffee church-key roof party.Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornhole single-origin coffee church-key roof party.</p> -->
        <ul class="single-detail">
          <li>{{getTimeofBlogs(blog?.created)}}</li>
          &nbsp;<li><a ><img src="assets/images/like-img.png">Like</a></li>
          <li><a style="cursor: pointer;" (click)="copyforShareLocation()"><img src="assets/images/share-img.png">Share</a></li>
        </ul>
      </div>

    </div>
  </div>
</div>
<!--Footer Section Start-->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-xs-6 col-sm-3">
        <div class="footer-inner">
          <h5>About Royal</h5>
          <address>
            Bushwick meh Blue Bottle pork belly mustache skateboard 3 wolf moon. Actually beard single-origin coffee,
            twee 90's PBR Echo Park.
          </address>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3">
        <div class="footer-inner">
          <h5 style="padding-inline-start: 30px">Quick Links</h5>
          <ul>
            <li><a [routerLink]="['/dating/hire-professional']">Hire Professional</a></li>
            <li><a [routerLink]="['/dating/my-agent']">My Booking</a></li>
            <li><a [routerLink]="['/dating/hire-professional']">Get Agent</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3">
        <div class="footer-inner">
          <h5 style="padding-inline-start: 30px">More Information</h5>
          <ul>
            <li><a [routerLink]="['/blog']">Blog</a></li>
            <li><a [routerLink]="['/dating/subscription']">Subscription</a></li>
            <li><a [routerLink]="['/dating/my-agent']">My Agent</a></li>
          </ul>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3 social-icons">
        <div class="footer-inner">
          <h5 style="padding-inline-start: 30px">We are social</h5>
          <ul class="social-footer">
            <li class="fb"><a><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li class="linkedin"><a><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            <li class="twitter"><a><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</footer>
<div class="copy-write">
  <div class="container">
    <div class="row">
      <div class="col-xs-6 col-sm-6 common-copy">
        <div class="copy-content">
          © 2023 LifeSet All Rights Reserved.
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 common-copy">
        <ul class="flex-mkt terms-block">
          <li><a [routerLink]="['/dating/privacy-policy']">Privacy Policy</a></li>
          <li><a [routerLink]="['/dating/terms-and-condition']">Terms And Condition</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!--Footer Section End-->


<!-- custom plugin js End here -->

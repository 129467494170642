import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ErrorComponent } from './components/error/error.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastrModule } from 'ngx-toastr';


@NgModule({
    declarations: [
      LoginComponent, 
      RegisterComponent, 
      ResetPasswordComponent, 
      ForgetPasswordComponent, 
      ErrorComponent
      // , VerifyOtpComponent
    ],
  exports: [
    LoginComponent,
    RegisterComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule
  ]
})
export class AuthModule { }

 <div id="wrapper hero">
<!--  <app-header></app-header>-->

   <div class="cover">
     <!-- <img src="assets/images/cover.png" alt="no image"> -->
     <video [src]="videoUrl" style="height: 100%; width: 100%;" controls></video>
   </div>


  <h2 class="common-title">Top 10 Liked Profiles Of The Day</h2>
   <p style="margin-left: 2%;" *ngIf="!this.httpService.getToken()">Please login into the system to see this information.</p>
   <!-- <swiper [slidesPerView]="5"  [spaceBetween]="20" [autoplay]="true" loop="true"  *ngIf="topLike?.length>0">
    <ng-template swiperSlide *ngFor="let like of topLike ; index as i">
      <div class="profile-block">
      <div class="user-img">
        <a >
          <div class="profile-img">
            <img src="{{like.profileImage}}" alt="" />
          </div>
          <div class="user-identification">
            <h2>{{like.name}} <span>{{like.distance}}KM</span></h2>
            <address>{{like.about}}</address>
            <span class="online"></span>
          </div>
        </a>
      </div>
    </div>
    </ng-template>
   </swiper> -->
   <swiper [slidesPerView]="3"   [navigation]="true" *ngIf="topLike?.length>0" 
    [pagination]="false" [loop]="true"
    [autoplay]="true"
    [scrollbar]="false"[spaceBetween]="0" [allowSlidePrev]="true" >
      <ng-container *ngFor="let slide of topLike; index as i">
        <ng-template swiperSlide>
          <div class="swip-block">
            <a [routerLink]="['/swipe-detail', slide._id]">
            <div>
              <figure><img style="height: 330px; width: 270px;" src="{{slide.profileImage}}" onerror="this.src='assets/images/mask-group.jpg';" alt=""></figure>
            </div>
              <div class="swip-content">
                <h1>{{slide.name}}</h1>
                <div class="loc-block">
                  Distance {{slide.distance |number}} KM
                </div>
              </div>
            </a>
          </div>
        </ng-template>
    </ng-container>
  </swiper>

  <h2 class="common-title">Top 10 Super Liked Profiles Of The Day</h2>
  <p style="margin-left: 2%;" *ngIf="!this.httpService.getToken()">Please login into the system to see this information.</p>

  <!-- <swiper [slidesPerView]="5" [spaceBetween]="20" [autoplay]="true" loop="true"
  *ngIf="topSuperLike?.length>0" >
    <ng-template swiperSlide *ngFor="let superLike of topSuperLike ; index as i"> <div class="profile-block">
      <div class="user-img">
        <a >
          <div class="profile-img">
            <img src="{{superLike.profileImage}}" alt="" />
          </div>
          <div class="user-identification">
            <h2>{{superLike.name}} <span>{{superLike.distance}}KM</span></h2>
            <address>{{superLike.about}}</address>
            <span class="online"></span>
          </div>
        </a>
      </div>
    </div> </ng-template>
  </swiper> -->
  <swiper [slidesPerView]="3"   [navigation]="true" *ngIf="topSuperLike?.length>0" 
    [pagination]="false" [loop]="true"
    [autoplay]="true"
    [scrollbar]="false"[spaceBetween]="0" [allowSlidePrev]="true" >
      <ng-container *ngFor="let slide of topSuperLike; index as i">
        <ng-template swiperSlide>
          <div class="swip-block">
            <a [routerLink]="['/swipe-detail', slide._id]">
            <div>
              <figure><img style="height: 330px; width: 270px;" src="{{slide.profileImage}}" onerror="this.src='assets/images/mask-group.jpg';" alt=""></figure>
            </div>
              <div class="swip-content">
                <h1>{{slide.name}}</h1>
                <div class="loc-block">
                  Distance {{slide.distance |number}} KM
                </div>
              </div>
            </a>
          </div>
        </ng-template>
    </ng-container>
  </swiper>

  <h2 class="common-title">Trending Profiles this week</h2>
  <p style="margin-left: 2%;" *ngIf="!this.httpService.getToken()">Please login into the system to see this information.</p>
  <!-- <swiper [slidesPerView]="5" [spaceBetween]="20" [autoplay]="true" loop="true"
  *ngIf="trending?.length>0" >
    <ng-template swiperSlide *ngFor="let trend of trending ; index as i"> <div class="profile-block">
      <div class="user-img">
        <a >
          <div class="profile-img">
            <img src="{{trend? trend.profileImage: ''}}" alt="" />
          </div>
          <div class="user-identification">
            <h2>{{trend?trend.name:''}} <span>{{trend?trend.distance:''}}KM</span></h2>
            <address>{{trend? trend.about:''}}</address>
            <span class="online"></span>
          </div>
        </a>
      </div>
    </div> </ng-template>
  </swiper> -->

  <swiper [slidesPerView]="3"   [navigation]="true" *ngIf="trending?.length>0" 
    [pagination]="false" [loop]="true"
    [autoplay]="true"
    [scrollbar]="false"[spaceBetween]="50" [allowSlidePrev]="true" >
      <ng-container *ngFor="let slide of trending; index as i">
        <ng-template swiperSlide>
          <div class="swip-block">
            <a [routerLink]="['/swipe-detail', slide._id]">
            <div>
              <figure><img style="height: 330px; width: 270px;" src="{{slide.profileImage}}" onerror="this.src='assets/images/mask-group.jpg';" alt=""></figure>
            </div>
              <div class="swip-content">
                <h1>{{slide.name}}</h1>
                <div class="loc-block">
                  Distance {{slide.distance |number}} KM
                </div>
              </div>
            </a>
          </div>
        </ng-template>
    </ng-container>
  </swiper>

  <h2 class="common-title">Trending profiles of the day</h2>
  <p style="margin-left: 2%;" *ngIf="!this.httpService.getToken()">Please login into the system to see this information.</p>
  <!-- <swiper [slidesPerView]="5" [spaceBetween]="20" [autoplay]="false" loop="false"
  *ngIf="this.trendingProfilesDay?.length>0" >
    <ng-template swiperSlide *ngFor="let trend of this.trendingProfilesDay ; index as i"> <div class="profile-block">
      <div class="user-img">
        {{i}}
        <a >
          <div class="profile-img">
            <img src="{{trend? trend.profileImage: ''}}" alt="" />
          </div>
          <div class="user-identification">
            <h2>{{trend?trend.name:''}} <span>{{trend?trend.distance:''}}KM</span></h2>
            <address>{{trend? trend.about:''}}</address>
            <span class="online"></span>
          </div>
        </a>
      </div>
    </div> </ng-template>
  </swiper> -->
  <swiper [slidesPerView]="3"   [navigation]="true" *ngIf="trendingProfilesDay?.length>0" 
    [pagination]="false" [loop]="true"
    [autoplay]="true"
    [scrollbar]="false"[spaceBetween]="50" [allowSlidePrev]="true" >
      <ng-container *ngFor="let slide of trendingProfilesDay; index as i">
        <ng-template swiperSlide>
          <div class="swip-block">
            <a [routerLink]="['/swipe-detail', slide._id]">
            <div>
              <figure><img style="height: 330px; width: 270px;" src="{{slide.profileImage}}" onerror="this.src='assets/images/mask-group.jpg';" alt=""></figure>
            </div>
              <div class="swip-content">
                <h1>{{slide.name}}</h1>
                <div class="loc-block">
                  Distance {{slide.distance |number}} KM
                </div>
              </div>
            </a>
          </div>
        </ng-template>
    </ng-container>
  </swiper>

</div>

import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-nearme',
  templateUrl: './nearme.component.html',
  styleUrls: ['./nearme.component.css'],
})
export class NearmeComponent implements OnInit {
  constructor() {}

  lat = 51.678418;
  lng = 7.809007;
  findNearMe = () => {
    let size = 140;
    setInterval(() => {
      $('.pulse').width(size).height(size);
      size++;
      if (size > 200) {
        $('.pulse').css('opacity', $('.pulse').css('opacity') - 0.001);
      }
      if (size > 500) {
        size = 50;
        $('.pulse').width(size).height(size);
        $('.pulse').css('opacity', '0.3');
      }
    }, 0);

    $('.photo').click(() => {
      console.log('CLick');
      $('.pulse').clone().appendTo('body');
    });
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(displayLocationInfo);
    // }

    // function displayLocationInfo(position) {
    //   const lng = position.coords.longitude;
    //   const lat = position.coords.latitude;

    //   console.log(`longitude: ${lng} | latitude: ${lat}`);
    // }
  };
  ngOnInit(): void {
    this.findNearMe();
    if ('geolocation' in navigator) {
      console.log('availa');
      navigator.geolocation.getCurrentPosition((position: any) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        document.getElementById('lat').textContent = lat;
        document.getElementById('long').textContent = long;
      });
    } else {
      console.log('not');
    }
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpVerb } from 'src/app/core/services/enum/const.enum';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-my-agent',
  templateUrl: './my-agent.component.html',
  styleUrls: ['./my-agent.component.css']
})
export class MyAgentComponent implements OnInit {
  @ViewChild('closeAgentModal') closeAgentModal: ElementRef;
  @ViewChild('closeProfessionalModal') closeProfessionalModal: ElementRef;
  
  agentRatingForm: FormGroup;
  professionalRatingForm: FormGroup;
  bookingsList: any;
  agentList: any
  constructor(private fb: FormBuilder,
    public httpService: HttpService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.myBookings();
    this.agentBoooking();
    this.agentRatingForm = this.fb.group({
      rating: ['', [Validators.required]],
      message: ['', [Validators.required]],
    })

    this.professionalRatingForm = this.fb.group({
      rating: ['', [Validators.required]],
      message: ['', [Validators.required]],
    })
  }

  myBookings(){
    this.httpService.httpRequest(`user/myBooking`, HttpVerb.GET).
      subscribe((response: any) => {
        this.bookingsList = response.responseData.data;
        console.log(response);
      })
  }

  agentBoooking(){
    this.httpService.httpRequest(`user/myAgentBooking`, HttpVerb.GET).
      subscribe((response: any) => {
        this.agentList = response.responseData.data;
        console.log(response);
      })
  }

  rateAgentService(){
    // console.log(this.agentRatingForm.value)
    this.httpService.httpRequest(`user/rateAgentService`, HttpVerb.POST, this.agentRatingForm.value).
      subscribe((response: any) => {
        // console.log(response);
        if(response.responseCode == 200){
          this.closeAgentModal.nativeElement.click();
          this.toastr.success("Ratings Submitted");
        }
      })
  }

  rateProfessionalService(){
    // console.log(this.agentRatingForm.value)
    this.httpService.httpRequest(`user/rateProfessionalService`, HttpVerb.POST, this.professionalRatingForm.value).
      subscribe((response: any) => {
        // console.log(response);
        if(response.responseCode == 200){
          this.closeProfessionalModal.nativeElement.click();
          this.toastr.success("Ratings Submitted");
        }
      })
  }

}
